import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { diffWordsWithSpace } from 'diff';
import '../../App.css';
import './sentence-transform-qna.css';
import { AppHelper } from "../../AppHelper";

export default function SentenceTransformQnA({pageIndex, questionNo, questionText, answer, status, correctedAnswer, onEdit}) {
	const [record, setRecord] = useState({});
	const [originalData, setOriginalData] = useState(null);
	const [transformedData, setTransformedData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	function parseAndUnderline(text) {
		const parts = text.split(/<\/?u>/);
		return parts.map((part, index) => {
			if (index % 2 === 1) {
				// return <span key={index} style={{textDecoration:'underline'}}>{part}</span>;
				return <span key={index} className="custom-underline">{part}</span>;
			}else{
				return <span key={index}>{part}</span>;
			}
		});
	}
	function answerTextRedOrBlue(str1, str2) {
		// console.log(str1);
		// console.log(str2);
		var str2_full = str1.replace(/<u>.*?<\/u>/, str2);
		var str1_clean = str1.replaceAll('<u>', '').replaceAll('</u>', '');
		var str2_clean = str2_full.replaceAll('<a>', '').replaceAll('</a>', '');
		var _diff = getDifferences(str1_clean, str2_clean);
		//console.log(_diff);
		return _diff;
    // const uTags = [...str1.matchAll(/<u>(.*?)<\/u>/g)];
    // const aTags = [...str2.matchAll(/<a>(.*?)<\/a>/g)];
    // let result = str1;
    // uTags.forEach((match, index) => {
    //     if (aTags[index]) {
    //         const aContent = aTags[index][0]; // Get the entire <a> tag
    //         result = result.replace(match[0], `${match[0]}${aContent}`);
    //     }
    // });

		// result = result.replaceAll("<u>", `<span class="answer-text-NO">`);
		// result = result.replaceAll("</u>","</span>");
		// result = result.replaceAll("<a>", `<span class="answer-text-YES">`);
		// result = result.replaceAll("</a>","</span>");
    // return result;
	}

	function normaliseCorrectAnswer(input) {
		const trimmed = input.trim();
		if (!/[.!?]$/.test(trimmed)){
			return `${trimmed}.`;
		}
		return trimmed;
	};

	const getDifferences = (line1, line2) => {
		line2 = normaliseCorrectAnswer(line2);
		if(!line1 || !line2) return <span></span>;
		const differences = diffWordsWithSpace(line1, line2);
		return differences.map((part, index) => {
			const style = {
				color: part.added ? '#1976d2' : part.removed ? '#ef5350' : '#000',
				textDecoration: part.added? 'underline' : part.removed ? 'line-through' : 'none',
				fontWeight: (part.added || part.removed) ? 'bold' : 'normal',
			};
			const textContent = (part.added || part.removed) ? ` ${part.value}` : part.value;
			return <span key={index} style={ style }>{convertNewlinesToBr(textContent)}</span>;
			// return <span key={index} style={ style }>{convertNewlinesToBr(part.value)}</span>;
		});
	};
	const convertNewlinesToBr = (text) => {
		if(!text) return <React.Fragment></React.Fragment>;
		text = text.replace(/<[^>]*>/g, '');
		return text.split('\n').map((line, index) => (
			<React.Fragment key={index}>
				{line}
				{index < text.split('\n').length - 1 && <br />}
			</React.Fragment>
		));
	};

	return(
		<Box>
			<Grid sx={{pt:2, pb:2, pl:1}}>
				<Box display={'flex'} mb={.5}>
					{ status == 1 &&
						<ArrowRightIcon sx={{marginRight:'.4rem', marginLeft:'.3rem', color:'rgba(19,130,117,1)'}} />
					}
					{ status != 1 &&
						<ArrowRightIcon sx={{marginRight:'.4rem', marginLeft:'.3rem', color:'#f44336'}} />
					}
					{/* <ArrowRightIcon sx={{marginRight:'.4rem', marginLeft:'.3rem', color:'#e666cc'}} /> */}
					
					{/* <CheckIcon sx={{marginRight:'.4rem', marginLeft:'.3rem', color:'rgba(19,130,117,1)'}} /> */}
					<Typography sx={{fontWeight:'bold', textTransform:'uppercase', color:'rgba(0,0,0,.6)'}}>Page {pageIndex+1}, Question {questionNo}</Typography>
					<Button onClick={onEdit} startIcon={<EditIcon sx={{color:'rgba(0,0,0,.6)', marginLeft:'0.5rem', marginTop:'-5px'}}></EditIcon>}></Button>
				</Box>
				<Box display={'flex'} alignItems={'center'} justifyItems={'center'} marginBottom={'.2rem'}>
					{/* <HelpIcon sx={{ color:'#424242', marginRight:'.2rem', opacity:.7}} /> */}
					<Box className='qna-circle'>
						<Typography sx={{ 
							color:'rgba(0,0,0,.6)', fontSize:'1.5rem', fontWeight:'bold',
							minWidth:'1.8rem', textAlign:'center', }}>Q</Typography>
					</Box>
					{/* <Typography color={'rgba(0,0,0,1)'}></Typography> */}
					<span style={{fontWeight:'bold'}}>{questionText}</span>
				</Box>
				<Box display={'flex'}>
					{/* <RadioButtonCheckedIcon sx={{ color:'#424242', marginRight:'.2rem', opacity:.7}} /> */}
					<Box className='qna-circle'>
						<Typography sx={{ 
								color:'rgba(0,0,0,.6)', fontSize:'1.5rem', fontWeight:'bold',
								minWidth:'1.8rem', textAlign:'center', }}>A</Typography>
					</Box>
					{/* <Typography></Typography> */}
					{status == 1 && 
						parseAndUnderline(answer)
					}
					{status != 1 && 
						// <div dangerouslySetInnerHTML={{ __html: answerTextRedOrBlue(answer, correctedAnswer) }}></div>
						<div>
							{answerTextRedOrBlue(answer, correctedAnswer)}
						</div>
					}
				</Box>
			</Grid>
		</Box>
	);
}