import React, { useState, useEffect, useRef } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { alpha } from "@mui/material/styles";
import { CheckCircle, Delete, Block } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import AppTopBar2 from "./common/app-top-bar2";
import AppLoading from "./common/app-loading";
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import PopInviteEmail from './pop-invite-email';
import {
  Avatar,
  AppBar,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Fab,
  Container,
  Divider,
  Drawer,
  TablePagination,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import LinearProgress from "@mui/material/LinearProgress";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneNumber from "libphonenumber-js";
import { AppHelper } from "../AppHelper";
import { EssayHelper } from "../helpers/essay.helper";
import EssayForm from "./essay/essay-form";
import EssayView from "./essay/essay-view";
import SpaCheck from "./common/spa-check";
import PopAccessRights from "./pop-access-rights";
const dayjs = require("dayjs");

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? "#fff" : "text.secondary",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  { id: "rowIndex", numeric: true, disablePadding: false, label: "#" },
  { id: "grade", numeric: false, disablePadding: false, label: "GRADE" },
  { id: "topic", numeric: false, disablePadding: false, label: "TITLE" },
  { id: "avgScore", numeric: true, disablePadding: false, label: "SCORE" },
  { id: "version", numeric: false, disablePadding: false, label: "VERSION " },
  { id: "vCorr", numeric: false, disablePadding: false, label: "CORRECTION" },
  { id: "tCode", numeric: false, disablePadding: false, label: "SUBMITTED ON" },
  { id: "email", numeric: false, disablePadding: false, label: "SUBMITTED BY" },
  { id: "name", numeric: false, disablePadding: false, label: "PROFILE" },
  { id: "essayId", numeric: false, disablePadding: false, label: "..." },
];
const headCellsProfile = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "essayCount", numeric: true, disablePadding: false, label: "ESSAYS" },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "accountType",
    numeric: false,
    disablePadding: false,
    label: "ACCOUNT TYPE",
  },
  { id: "status", numeric: false, disablePadding: false, label: "STATUS" },
];

const headCellsAcc = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  {
    id: "totalProfiles",
    numeric: true,
    disablePadding: false,
    label: "PROFILES",
  },
  { id: "totalEssays", numeric: true, disablePadding: false, label: "ESSAYS" },
  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  {
    id: "tCodeCreatedOn",
    numeric: true,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "tCodeLastLogin",
    numeric: true,
    disablePadding: false,
    label: "LAST LOGIN",
  },
];


const headCellsTui = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "accessTitle", numeric: false, disablePadding: false, label: "ACCESS" },
  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  {
    id: "tCodeCreatedOn",
    numeric: true,
    disablePadding: false,
    label: "CREATED ON",
  },
  // {
  //   id: "tCodeLastLogin",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "LAST LOGIN",
  // },
  
];

const headCellsTut = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  {
    id: "tCodeCreatedOn",
    numeric: true,
    disablePadding: false,
    label: "CREATED ON",
  },
  
];

const headCellsFeedback = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "profileId", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "essayId", numeric: false, disablePadding: false, label: "ESSAY" },
  { id: "feedback", numeric: false, disablePadding: false, label: "FEEDBACK" },
  {
    id: "feedbackType",
    numeric: false,
    disablePadding: false,
    label: "FEEDBACK TYPE",
  },
  {
    id: "createdOn",
    numeric: false,
    disablePadding: false,
    label: "TIMESTAMP",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  
   const headCells =
    props.gid == "A1"
      ? headCellsAcc
      : props.gid == "G1"
	  ? headCellsEssay
      : props.gid == "T1"
      ? headCellsTui
      : props.gid == "F1"
      ? headCellsFeedback
	  : props.gid == "R1"
      ? headCellsTut
      : headCellsProfile;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PageTutorInvite() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { pageEssayId } = useParams();
  const isPhoneVerifed =
    oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;
  const today = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [recProfile, setRecProfile] = useState({});
  const [essayList, setEssayList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [originalProfileList, setOriginalProfileList] = useState([]);
  
  const [tutorList, setTutorList] = useState([]);
  const [originalTutorList, setOriginalTutorList] = useState([]);
  
  const [accList, setAccList] = useState([]);
  const [originalAccList, setOriginalAccList] = useState([]);
  const [tuiList, setTuiList] = useState([]);
  const [originalTuiList, setOriginalTuiList] = useState([]);
  
  const [tutList, setTutList] = useState([]);
  const [originalTutList, setOriginalTutList] = useState([]);
  
  const [feedbackList, setFeedbackList] = useState([]);

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("LIST-TUT");
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState("");
  const [countEssay, setCountEssay] = React.useState(0);
  const [countUser, setCountUser] = React.useState(0);
  const [countAcc, setCountAcc] = React.useState(0);
  const [countTui, setCountTui] = React.useState(0);
  const [countTut, setCountTut] = React.useState(0);
  const [countFeedback, setCountFeedback] = React.useState(0);
  
  const [tuiton, setTuiton] = React.useState("");

  const [tblOrderEssay, setTblOrderEssay] = React.useState("desc");
  const [tblOrderByEssay, setTblOrderByEssay] = React.useState("tCode");
  const [filterEssay, setFilterEssay] = React.useState("");

  const [tblOrderProfile, setTblOrderProfile] = React.useState("desc");
  const [tblOrderByProfile, setTblOrderByProfile] =
    React.useState("essayCount");
  const [filterProfile, setFilterProfile] = React.useState("");

  const [tblOrderAcc, setTblOrderAcc] = React.useState("desc");
  const [tblOrderByAcc, setTblOrderByAcc] = React.useState("totalProfiles");
  const [filterAcc, setFilterAcc] = React.useState("");
  
  const [tblOrderTui, setTblOrderTui] = React.useState("desc");
  const [tblOrderByTui, setTblOrderByTui] = React.useState("totalProfiles");
  const [filterTui, setFilterTui] = React.useState("");
  
  const [tblOrderTut, setTblOrderTut] = React.useState("desc");
  const [tblOrderByTut, setTblOrderByTut] = React.useState("totalProfiles");
  const [filterTut, setFilterTut] = React.useState("");

  const [tblOrderFeedback, setTblOrderFeedback] = React.useState("desc");
  const [tblOrderByFeedback, setTblOrderByFeedback] =
    React.useState("createdOn");
  const [filterFeedback, setFilterFeedback] = React.useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  const scoreCodesV2_sa = [
    {
      c: "GM",
      c2: "grammar",
      n: "Grammar",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "SP",
      c2: "spelling",
      n: "Spelling",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "VC",
      c2: "vocabulary",
      n: "Vocabulary",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "ST",
      c2: "structure",
      n: "Structure",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    //{ c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
  ];

  useEffect(() => {
    if (!oUser) {
      navigate("/login");
      return;
    }
  });

  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date && endDate < date) {
      setEndDate(date);
    } else if (!endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (startDate && date && date < startDate) {
      setStartDate(date);
    } else if (!startDate) {
      setStartDate(date);
    }
  };

  const [isIncludeDeletedUser, setIsIncludeDeletedUser] = useState(false);
  const [isIncludeDeletedAcc, setIsIncludeDeletedAcc] = useState(false);
  const [isIncludeDeletedTui, setIsIncludeDeletedTui] = useState(false);
  const [isIncludeDeletedTut, setIsIncludeDeletedTut] = useState(false);
  const [popInviteEmailOpen, setPopInviteEmailOpen] = useState(false);
  const [popAccessRightsOpen, setPopAccessRightsOpen] = useState(false);

  useEffect(() => {
    var _list = originalProfileList.filter(
      (x) => x.status == 1 || isIncludeDeletedUser
    );
    setProfileList(_list);
    setCountUser(_list.length);
  }, [isIncludeDeletedUser]); // Run this effect whenever isIncludeDeletedUser changes

  useEffect(() => {
    var _list = originalAccList.filter(
      (x) => x.status == 1 || isIncludeDeletedAcc
    );
    setAccList(_list);
    setCountAcc(_list.length);
  }, [isIncludeDeletedAcc]); // Run this effect whenever isIncludeDeletedUser changes
  
  useEffect(() => {
    var _list = originalTuiList.filter(
      (x) => x.status == 1 || isIncludeDeletedTui
    );
    setTuiList(_list);
    setCountTui(_list.length);
  }, [isIncludeDeletedTui]); 
  
  useEffect(() => {
    var _list = originalTutList.filter(
      (x) => x.status == 1 || isIncludeDeletedTui
    );
    setTutList(_list);
    setCountTut(_list.length);
  }, [isIncludeDeletedTut]); 
  useEffect(() => {
	  const fetchTutList = async () => {
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/account/list/tutor", {
        token: oUser.token,
      });
      setIsLoading(false);
      if (!_res || !_res.status) {
        return;
      }
      _res.data = _res.data.filter(r=>r.centerId==oUser.profileId);
      var _resInvite = await AppHelper.apiPost("api/tuiton/invite/list", {
        token: oUser.token,
      });
      if(_resInvite && _resInvite.status){
        var _inviteList = _resInvite.data.filter(r=>r.centerId==oUser.profileId);
        for(var i=0; i<_inviteList.length;i++){
          var _invite = _inviteList[i];
          var _acc = _res.data.find(r=>r.email.toLowerCase() == _invite.email.toLowerCase());
          if(!_acc){
            _res.data.push({
              accountId:'INVITED',
              accountType:'TEA',
              name: '-',
              email: _invite.email,
              //profileId: '',
              status: 1
            });
          }
          else{
            //console.log('found', _acc);
          }
        }
      }
      _res.data.forEach((r, i) => {
        r.i = i;
        if (r.lastLogin) {
          var _ts = dayjs(r.lastLogin);
          r.lastLogin = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeLastLogin = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.lastLogin = "";
          r.tCodeLastLogin = "";
        }

        if (!AppHelper.isEmptyString(r.createdOn)) {
          var _ts = dayjs(r.createdOn);
          r.createdOn = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeCreatedOn = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.createdOn = "";
          r.tCodeCreatedOn = "";
        }
      });
      var _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];
	 
      setOriginalTutList(_list);
	  
	    //_list = _list.filter((x) => x.accountType == 'TEA');
      var _resAccess = await AppHelper.apiPost("api/access/all", {
        token: oUser.token,
      });
      var _gradeList=[
        {v:'P1', a:0}, {v:'P2', a:0}, {v:'P3', a:0},
        {v:'P4', a:0}, {v:'P5', a:0}, {v:'P6', a:0},
      ];
      var _accessAll = (_resAccess && _resAccess.status && _resAccess.data) ? _resAccess.data : [];
      for(var i=0;i<_list.length; i++){
        var _recTutor = _list[i];
        var _accessArr = _accessAll.filter(r=>r.profileId==_recTutor.profileId);
        _recTutor.access = _gradeList.map(g => ({ ...g }));//[..._gradeList];
        _recTutor.access.forEach(a=>{
          var _access = _accessArr.find(r=>r.grade == a.v);
          if(_access) a.a = _access.access;
        });
      }
	
      setTutList(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }
      setCountTut(_list.length);
    };
    const fetchIAM = async () => {
      if (!oUser) {
        navigate("/login");
        return;
      }
	  
	  var accountType = oUser.accountType || '';
	  
	  if(accountType != 'TUI')
	  {
		  var _res = await AppHelper.apiPost("api/account/iam", {
			token: oUser.token,
		  });
		  
		  if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
			setIsLoading(false);
			nonSaView();
			return;
		  }
	  }
	  
      fetchTutList();
    };
    fetchIAM();
    fetchTutList();
  }, [refreshList]);

  const essayListView = () => {
    //setViewMode("LIST");
	navigate("/saV2/essay");
  };
  const profileListView = () => {
    setViewMode("LIST-PROFILE");
  };
  const accListView = () => {
    setViewMode("LIST-ACC");
  };
  const tuiListView = () => {
    setViewMode("LIST-TUI");
  };
  const tutListView = () => {
    setViewMode("LIST-TUT");
  };
  const feedbackListView = () => {
    //setViewMode('LIST-FEEDBACK');
    navigate("/sa/feedbacks");
  };
  
  const onInviteEmail = ()=>{
	setTuiton("");
    setPopInviteEmailOpen(true);    
  }
  
  const popInviteEmailClose = ()=>{
	setTuiton("");
	setRefreshList(!refreshList);
    setPopInviteEmailOpen(false);
  }
  const onPopAccessRights = (arg)=>{
    setPopAccessRightsOpen(true);
    setRecProfile(arg);
  }
  const popAccessRightsClose = ()=>{
    setPopAccessRightsOpen(false);
  }
  
  const cardView = () => {
    setIsIncludeDeletedUser(false);
    setViewMode("CARDS");
  };
  const nonSaView = () => {
    setViewMode("NON-SA");
  };
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  
  const listSortAndFilterTut = () => {
    var _list = [...tutList];
    if (filterTut && filterTut.length > 0) {
      var _filterText = filterTut.toLowerCase();
      _list = _list.filter((r) => r.email.toLowerCase().includes(_filterText));
    }
    return stableSort(_list, getComparator(tblOrderTut, tblOrderByTut));
  };
  
  const visibleTuts = React.useMemo(
    () => listSortAndFilterTut(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],
    [tutList, tblOrderTut, tblOrderByTut, filterTut]
  );
  
  const tblSortTut = (event, property) => {
    const isAsc = tblOrderByTut === property && tblOrderTut === "asc";
    setTblOrderTut(isAsc ? "desc" : "asc");
    setTblOrderByTut(property);
  };
  
  return (
    <>
    <Box sx={{ display: "flex" }}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading ? (
        <AppLoading />
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              //width: { md: `calc(100% - ${drawerWidth}px)` },
              width: "100%",
              marginTop: "50px",
            }}
          >
            {viewMode == "CARDS" && (
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                  </Typography> */}
                        <Typography variant="h3" component="div">
                          {countEssay}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          ESSAYS
                        </Typography>
                        <Typography variant="body2">
                          Total number of submitted essays.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={essayListView} size="small">
                          More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                  </Typography> */}
                        <Typography variant="h3" component="div">
                          {countUser}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          PROFILES
                        </Typography>
                        <Typography variant="body2">
                          Total number of active profiles.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={profileListView} size="small">
                          More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                  </Typography> */}
                        <Typography variant="h3" component="div">
                          {countAcc}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          ACCOUNTS
                        </Typography>
                        <Typography variant="body2">
                          Total number of active accounts.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={accListView} size="small">
                          More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
				  
				  
				   <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                  </Typography> */}
                        <Typography variant="h3" component="div">
                          {countTui}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          TUITION CENTERS
                        </Typography>
                        <Typography variant="body2">
                          Total number of active tuition center.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={tuiListView} size="small">
                          More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
				  
				  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                  </Typography> */}
                        <Typography variant="h3" component="div">
                          {countTut}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          TUTORS
                        </Typography>
                        <Typography variant="body2">
                          Total number of active tutor.
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={tutListView} size="small">
                          More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                      <CardContent>
                        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Word of the Day
                  </Typography> */}
                        <Typography variant="h3" component="div">
                          {countFeedback}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          FEEDBACKS
                        </Typography>
                        <Typography variant="body2">
                          Total number of feedbacks. (past 7 days)
                          {/* <br />
                    {'"a benevolent smile"'} */}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button onClick={feedbackListView} size="small">
                          More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
			      {viewMode == "LIST-TUT" && (
              <>
                <Grid container>
                  {true  &&
                  <Button
                    onClick={() => onInviteEmail()}
                    className="app-btn-bg"
                    color="info"
                    variant="contained"
                    size="large"
                    style={{ marginRight: '2px', marginBottom: '8px' }} // Adjust the marginBottom value as needed
                    endIcon={<EmailIcon />}
                  >
                    SEND INVITE
                  </Button>
                  }
                </Grid>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <EnhancedTableHead
                      //numSelected={selected.length}
                      order={tblOrderTut}
                      orderBy={tblOrderByTut}
                      //onSelectAllClick={handleSelectAllClick}
                      onRequestSort={tblSortTut}
                      gid="T1"
                      rowCount={tutList.length}
                    />
                    <TableBody>
                      {visibleTuts.map((row, iTut) => (
                        <TableRow
                          key={row.profileId}
                          sx={{
                            
                          }}
                        >
                          <TableCell align="center">{iTut + 1}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
						              <TableCell align="left">{row.email}</TableCell>
                          {/* <TableCell align="left">{row.accessTitle}</TableCell> */}
                          <TableCell align="left">
                            {row.accountId != 'INVITED' &&
                            <>
                              {/* {row.accessTitle}  */}
                              { row.access && row.access.map(((recAcc,iAcc)=>(
                                <React.Fragment key={'chipAcc'+iAcc}>
                                  { (recAcc.a==1 || recAcc.a==2) &&
                                    <Chip label={recAcc.v} color="secondary" sx={{marginRight:'2px', 
                                      backgroundColor: recAcc.a==1?'#1976d2':'#138275'}} />
                                  }
                                </React.Fragment>
                              )))
                              }
                              <Tooltip title="access rights">
                                <IconButton onClick={() => onPopAccessRights(row)}>
                                <EditIcon style={{color:'#138275'}} />
                                </IconButton>
                              </Tooltip>
                            </>
                            }
                          </TableCell>
                          <TableCell align="right">
                            {/* {row.inviteStatus} */}
                            {row.accountId=='INVITED'?'INVITED':'ACTIVE'}
                          </TableCell>
                          <TableCell align="right">{row.createdOn?new Date(row.createdOn).toLocaleString() : ''}</TableCell>
                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </>
      )}
      <SpaCheck></SpaCheck>
    </Box>
	  <PopInviteEmail
      tutList={tutList}
      open={popInviteEmailOpen} tuiton={tuiton} onClose={popInviteEmailClose}      
    />
	  <PopAccessRights profile={recProfile}
      open={popAccessRightsOpen} tuiton={tuiton} onClose={popAccessRightsClose}      
    />
    </>
  );
}
