import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import CssBaseline from "@mui/material/CssBaseline";
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppLoading from './common/app-loading';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Avatar, AppBar, CircularProgress, Button, Box, Card, CardActions, CardContent, Chip, Fab, Container, Divider, Drawer, 
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Select, Toolbar, Typography, MenuItem, FormControl, InputLabel, TextField, Grid,
  Table, TableContainer, TableCell, TableHead, TableBody, TableRow, TableSortLabel, 
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinearProgress from '@mui/material/LinearProgress';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import PhoneNumber from 'libphonenumber-js';
import { AppHelper } from '../AppHelper';
import SpaCheck from './common/spa-check';
const dayjs = require('dayjs');

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography variant="body2" color="text.secondary" fontSize={"0.7rem"}
          sx={{
            color: props.recSelected==1?'#fff':'text.secondary'
          }}>
          {`${Math.round(props.value)}%`}
          </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function ImageDialog({ open, imageUrls, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
	 <DialogTitle>Feedback Image</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          {imageUrls ? (
            imageUrls.map((imageUrl, index) => (
              <Grid item key={index} xs={6} sm={4} md={3}>
                <img src={`data:image/jpeg;base64,${imageUrl}`} alt={`Image ${index + 1}`} style={{ maxWidth: '100%' }} />
              </Grid>
            ))
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </DialogContent>
	  <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
        <CloseIcon />
      </Button>
    </Dialog>
  );
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  { id: 'createdOn', numeric: false, disablePadding: false, label: 'TIMESTAMP' },
  { id: 'profileId', numeric: false, disablePadding: false, label: 'USER' },  
  { id: 'essayId', numeric: false, disablePadding: false, label: 'ESSAY' },
  { id: 'feedback', numeric: false, disablePadding: false, label: 'FEEDBACK' },
  { id: 'feedbackType', numeric: false, disablePadding: false, label: 'FEEDBACK TYPE' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'ACTIONS' }
 
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = headCellsEssay ;


  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PageSaUserLogList() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  
  const today = new Date();
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const isPhoneVerifed = oUser && oUser.phoneNumber && oUser.phoneNumber.length>0;
  
  const [imageUrls, setImageUrls] = useState([]);

  
  const [isLoading, setIsLoading] = useState(true);
  const [logList, setLogList] = useState([]);
  
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());
  const [dateRange, setDateRange] = React.useState([dayjs().subtract(7, 'day'), dayjs()]);

  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  const handleStartDateChange = (date) => {
	  setStartDate(date);
		//console.log(date, endDate);
	  if (endDate && date && endDate < date) {
		setEndDate(date);
	  }
	  else if(!endDate)
	  {
		  setEndDate(date);
	  }
	};

	const handleEndDateChange = (date) => {
	  setEndDate(date);
//console.log(date, startDate);
	  if (startDate && date && date < startDate) {
		setStartDate(date);
	  }
	  else if(!startDate)
	  {
		  setStartDate(date);
	  }
	};
  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('LIST');
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState('');
  const [countEssay, setCountLog] = React.useState(0);
  const [countUser, setCountUser] = React.useState(0);

  const [tblOrderLog, setTblOrderLog] = React.useState('desc');
  const [tblOrderByLog, setTblOrderByEssay] = React.useState('tCode');
  const [filterLog, setFilterLog] = React.useState('');

  const [tblOrderAcc, setTblOrderAcc] = React.useState('desc');
  const [tblOrderByAcc, setTblOrderByAcc] = React.useState('essayCount');
  const [filterAcc, setFilterAcc] = React.useState('');


  useEffect(()=>{
    if(!oUser){
      navigate('/login');
      return;
    }
  });
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleViewIconClick = async (row) => {
    setSelectedRow(row);
	var _res = await AppHelper.apiPost('api/feedback/detail', {token: oUser.token, feedbackId:row.feedbackId});
	if(!_res || !_res.status){
        setImageUrls([]);
		alert("No image for this feedback");
        return;
    }
	else
	{
		console.log(_res.feedback.imgsBase64.length)
		setImageUrls(_res.feedback.imgsBase64);		
		setIsPopupOpen(true);
	}
  };

    const handleCloseDialog = () => {
    setIsPopupOpen(false);
    setImageUrls([]);
  };


  useEffect(()=>{
    const fetchLogList = async() =>{
      logList.splice(0);
      setIsLoading(true);
      // var _res = await AppHelper.apiPost('api/feedback/list', {token: oUser.token, startDate:startDate, endDate:endDate});
      var _res = await AppHelper.apiPost('api/feedback/list', {token: oUser.token, startDate:dateRange[0], endDate:dateRange[1]});
      console.log('_res', _res);
      if(!_res || !_res.status){
        setIsLoading(false);
        return;
      }
	  
   
      var _list = [...AppHelper.sortList(_res.feedbackList, 'createdOn', 2)];
      _list.forEach(r=>{
        r.words = r.feedback.split(' ');
        r.isLongText = r.words.length>100;
        r.isExpanded = false;
      });
      setLogList(_list);
      setTimeout(() => { console.log('logList', _list); }, 1000);
      setIsLoading(false);
      setCountLog(_list.length);
      
    };
   
		fetchLogList();
  }, [refreshList, startDate, endDate]);
  
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
 

  const drawerItemColrs = {
    '1x': 'rgba(0,0,0,0.25)',
    '1xx': 'rgb(157, 188, 152)',
    '1': 'rgb(99, 136, 137)',
    '0x': 'white',
    '0': 'rgb(235, 217, 180)',
  };
  const drawerItemColorsText = {
    '1': 'rgba(255,255,255,1)',
    '0': 'rgba(0,0,0,0.6)',
  };
  const mobileOpenClick = (arg)=>{
    setMobileOpen(!mobileOpen);
  };
  const listSortAndFilterEssay = ()=>{
    var _list = [...logList];
    if(filterLog && filterLog.length>0){
      var _filterText = filterLog.toLowerCase();
      _list = _list.filter(r=>
        r.profileId.toLowerCase().includes(_filterText)
        || r.feedback.toLowerCase().includes(_filterText)
		|| r.essayId.toLowerCase().includes(_filterText)
        || r.feedbackType.toLowerCase().includes(_filterText)
        
      );
    }
    return stableSort(_list, getComparator(tblOrderLog, tblOrderByLog));
  };
  const filterLogChange = (event)=>{
    setFilterLog(event.target.value);
  };
  
   const backToOverview = ()=>{
    //setViewMode('LIST-FEEDBACK');
	navigate('/sa');
  }
  const visibleLogs = React.useMemo(
    () =>
      listSortAndFilterEssay()
      //stableSort(logList, getComparator(tblOrderLog, tblOrderByLog))
      // .slice(
      //   page * rowsPerPage,
      //   page * rowsPerPage + rowsPerPage,
      // )
      ,
    //[order, orderBy, page, rowsPerPage],
    [logList, tblOrderLog, tblOrderByLog, filterLog]
  );
  const tblSortLog = (event, property) => {
    const isAsc = tblOrderByLog === property && tblOrderLog === 'asc';
    setTblOrderLog(isAsc ? 'desc' : 'asc');
    setTblOrderByEssay(property);
  };
  const toggleExpanded = (arg)=>{
    var _list = [...logList];
    var _rec = _list.find(x=>x.feedbackId==arg.feedbackId);
    if(!_rec) return;
    _rec.isExpanded = !_rec.isExpanded;
    setLogList(_list);
  }

  const container = window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{display:'flex'}}>
      <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
      {isLoading? (
        <AppLoading />
      ):(
      <>
      {/* <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md:0 }, border:'0 solid red' }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'none' },
            minWidth: '300px'
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            backgroundColor:'red',
            minWidth:'300px'
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#f9efdb", //color: "red",
              marginTop:'65px',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, 
          //width: { md: `calc(100% - ${drawerWidth}px)` },
          width: '100%',
          marginTop:'50px' 
        }}
      >
        
        { viewMode=='LIST' &&
          <>
            <Grid container  spacing={0}>
			
			  <Grid container>
              <Grid item xs={6} sm={6}>
                <Button onClick={backToOverview}>&laquo; Back to Overview</Button>
              </Grid>
              <Grid item xs={6} sm={6}>
               
              </Grid>
            </Grid>
			
			   <Grid item xs={3} sm={6}>
				 <Box m={.5} pt={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker 
					    format="DD/MM/YYYY"
              localeText={{ start: 'Start Date', end: 'End Date' }}
              value={dateRange}
              onChange={(newValue) => {
                setDateRange(newValue);
                handleStartDateChange(newValue[0]);
                handleEndDateChange(newValue[1]);
              }}
              renderInput={(startProps, endProps) => (
                <>
                <TextField {...startProps} sx={{width: '100%'}}/>
                <Box sx={{mx: 5}}> to </Box>
                <TextField {...endProps} sx={{width: '100%'}}/>
                </>
              )}
              />
          </LocalizationProvider>
				 </Box>
			  </Grid>
              <Grid item xs={6} sm={6}>
			   <Box m={.5} pt={1}>
                <TextField value={filterLog} onChange={filterLogChange} id="txtFilterLog" label="" variant="standard" placeholder="Search" fullWidth />
				</Box>
              </Grid>
            </Grid>
			
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                {/* <TableHead>
                  <TableRow>
                    <TableCell align="center">GRADE</TableCell>
                    <TableCell align="left">TITLE</TableCell>
                    <TableCell align="center">SCORE</TableCell>
                    <TableCell align="center">SUBMITTED ON</TableCell>
                    <TableCell align="left">SUBMITTED BY</TableCell>
                  </TableRow>
                </TableHead> */}
                <EnhancedTableHead
                  //numSelected={selected.length}
                  order={tblOrderLog}
                  orderBy={tblOrderByLog}
                  //onSelectAllClick={handleSelectAllClick}
                  onRequestSort={tblSortLog}
                  rowCount={logList.length}
                  gid='G1'
                />
                <TableBody>
                  {visibleLogs.map((row) => (
                    <TableRow
                      key={row.logId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{new Date(row.createdOn).toLocaleString()}</TableCell>
                     
                      <TableCell align="left">{row.profileId}</TableCell>
					  <TableCell align="left">{row.essayId}</TableCell>
                      <TableCell align="left">
                        {/* {row.feedback} */}
                        {row.isExpanded || !row.isLongText ? row.feedback : row.words.slice(0, 10).join(" ") + "..."}
                        {row.isLongText && (
                          <span onClick={()=>toggleExpanded(row)} style={{ color: "blue", cursor: "pointer" }}>
                            {row.isExpanded ? " Show Less" : " Show More"}
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="left">{row.feedbackType}</TableCell>
						<TableCell >
						  {row.imgs > 0 && (
							<IconButton onClick={() => handleViewIconClick(row)}>
							  <VisibilityIcon />
							</IconButton>
						  )}
						</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
        
       
       
      </Box>
	  <ImageDialog open={isPopupOpen} imageUrls={imageUrls} onClose={handleCloseDialog} />
      </>
      )}
      <SpaCheck></SpaCheck>
    </Box>
  );
}
