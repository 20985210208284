import React, { useState, useEffect, useRef } from "react";
import '../App.css';
import { useNavigate } from 'react-router-dom';
import AppTopBar2 from './common/app-top-bar2';
import AppLoading from './common/app-loading';
import { Box, Grid } from '@mui/material';
import { AppHelper } from '../AppHelper';
import { useEssayContext } from "../contexts/context-essay";
const dayjs = require('dayjs');

export default function PageHome() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  var oUser = AppHelper.userCacheGet();
  
  const [isLoading, setIsLoading] = useState(false);
  const drawerWidth = 0;//240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const scoreCodesV2 = [
    { c:'GM', c2: 'grammar', n:'Grammar', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'SP', c2: 'spelling', n:'Spelling', v:0, p:0, eg: [], sub: [], expand: false },
    { c:'VC', c2: 'vocabulary', n:'Vocabulary', v:0, p:0, eg: [], sub: [], expand: false }, 
    { c:'ST', c2: 'structure', n:'Structure', v:0, p:0, eg: [], sub: [], expand: false },
  ];
  const [profileId, setProfileId] = useState('');
  const refTopBar = useRef(null);
  const [isSa, setIsSa] = React.useState(false);


  useEffect(()=>{
    if(!oUser){
      navigate('/login');
      return;
    }
    AppHelper.apiAuditPost("P-H", "");
    if(!oUser.profileId){
      console.log('no profile id', oUser);
    }
    if(profileId=='') setProfileId(oUser.profileId);

    const fetchIAM = async()=>{
      var _res = await AppHelper.apiPost('api/account/iam', {token: oUser.token});
      if(!_res || !_res.status || !_res.data || !_res.data.isSa){
        return;
      }
      setIsSa(true);
    }
    fetchIAM();
  }, []);
  const mobileOpenClick = (arg)=>{
    setMobileOpen(!mobileOpen);
    console.log('mobileOpenClick', mobileOpen);
  };
  const go2HomeEssay = ()=>{
    navigate('/home-essay');
  }
  const go2HomeExercise = ()=>{
    navigate('/home-exercise');
  }

  const container = window !== undefined ? () => window.document.body : undefined;
  return (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <AppTopBar2
      mobileOpen={mobileOpen}
      mobileOpenClick={mobileOpenClick}
      ref={refTopBar}
    />
    {isLoading ? (
      <AppLoading />
    ) : (
      <>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
            width: { md: `calc(100% - ${drawerWidth}px)` },
            marginTop: '50px',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="87vh"
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    flexDirection: {
                      xs: 'column', // Vertical layout for mobile
                      sm: 'row',   // Horizontal layout for tablet and desktop
                    },
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div onClick={go2HomeEssay} className="home-menu-circle">
                    ESSAY
                  </div>
                  <div onClick={go2HomeExercise} className="home-menu-circle">
                    EXERCISE
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    )}
  </Box>
);


}
