import React, { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import '../../App.css';
import {
	Avatar, Button, Box, Card, CircularProgress, FormControl, Grid, IconButton, Input, InputLabel, LinearProgress,
	Menu, MenuItem,
	Select, Stack, Switch, TextField, Typography, FormControlLabel, Checkbox,
	imageListClasses
} from '@mui/material';
import { AppHelper } from "../../AppHelper";
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import SentenceTransformImages from "./sentence-transform-images";

export default function SentenceTransformForm({oUser, submitDone}) {
	const [record, setRecord] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const [vMode, setVMode] = React.useState('NEW');
	const [invalidImg, setInvalidImg] = useState(false);


  useEffect(() => {
		setRecord({});
  }, []);

	const handleFileChange = (event) => {
		if(event.target.files.length<1) return;
		var _files = [];
		for(var i=0; i<event.target.files.length; i++){
			_files.push({
				file: event.target.files[i],
				url: URL.createObjectURL(event.target.files[i])
			});
		}
		setSelectedImages(_files);
  };

  const handleUploadClick = () => {
    const inputImg = document.getElementById("file-input"); // Trigger file input
	inputImg.value = "";
	inputImg.click();
  };
  const handleRemoveClick = (index) =>{
	setSelectedImages(selectedImages.filter((_, i) => i !== index));
  };
	const file2B64 = async(argFile)=>{
		try {
			const reader = new FileReader();
			reader.readAsDataURL(argFile);
			const isHeicFile = argFile.name.toLowerCase().endsWith('.heic');
			const isCropped = false;
			if (isCropped) {//-- disabled crop fun for now (original code in scan-popup-v1)
			}
			else{
				await new Promise((resolveReader, rejectReader) => {
					reader.onload = () => {
						resolveReader();
					};
					reader.onerror = (error) => {
						rejectReader(error);
					};
				})
				const base64 = reader.result.split(",")[1];
				return { b64: base64, fileType:argFile.type, fileSize:argFile.size, isHeicFile:isHeicFile, align:false };
			}
		}
		catch (error) {
			console.error("Error converting image to base64:", error);
			return null;
		}
	}
	const onSubmit = async ()=>{
		if(!oUser){
			alert('Invalid user!');
			return;
		}
		for(var i=0; i<selectedImages.length; i++){
			var _img = selectedImages[i];
			_img.b64 = (await file2B64(_img.file)).b64;
		}
		const totalSize = selectedImages.reduce((acc, x) => {
			const base64String = x.b64;
			const sizeB = new Buffer(base64String, "base64").length;
			const sizeMB = (sizeB / (1024 * 1024)).toFixed(2);
			return acc + sizeMB;
		}, 0);
		console.log("size MB", totalSize);
		if (totalSize > 30) {
			alert(
				"Total image size exceeds 30 MB. Please reduce the number or size of images."
			);
			setIsLoading(false); // Set loading state back to false
			return;
		}

		setIsLoading(true); // Set loading state to true
		const _imgArr = selectedImages.map((page) =>
			page.align ? page.alignB64 : page.b64
		);
		setVMode('SUBMITTING');
		
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/transcribe', { token: oUser.token, images: _imgArr, profileId: oUser.profileId });
		if(_res.status){
			setRecord({exerciseId: _res.data.exerciseId});
			pageStatusCheck({exerciseId: _res.data.exerciseId});
		}
		AppHelper.apiAuditPost("EX-ADD", "");
	}

	const pageStatusCheck = async (argRec) => {
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/get', {
			token: oUser.token,
			profileId: oUser.profileId,
			exerciseId: argRec.exerciseId
		});
		if (!_res.status) {
			console.error('API call failed:', _res);
			alert('ERROR');
			return;
		}
		var _rec = _res.data;
		setRecord(_rec);

		const pageCount = _rec.pageCount;
		const validImagesCount = _rec.pages.filter(page => page.status === 1).length;
		const invalidImagesCount = _rec.pages.filter(page => page.status === 6).length;

		// if (invalidImagesCount >= 1) {
		// 	setInvalidImg(true);
		// }
		
		if(_rec.pages.find(x=>x.status == 0)){
			setVMode('PROCESSING');
			setTimeout(() => {
				pageStatusCheck(_rec);
			}, 1000*3);
		}
		else if (invalidImagesCount ===  pageCount) {
			alert('Transcription failed for all images. Please try again.');
			setVMode('ERROR');
			window.location.reload();
		}
		else {
			console.log('all done *****');
			setVMode('DONE');
			submitDone({ code: 'NEW', argId: argRec.exerciseId });
		}

	}

	const openImageInNewTab = (blobUrl) => {
		window.open(blobUrl, "_blank", "noopener,noreferrer");
	};

	return(
		<>
		<Box sx={{ flexGrow: 1, backgroundColor:'#fff' }}>
			<Grid container>
				<Grid item xs={12} mb={1}>
					<Box sx={{fontWeight:'bold', fontFamily: "'Playpen Sans', cursive", fontSize:'2rem', textAlign:'center'}}>
						Sentence Transformation
					</Box>
				</Grid>
				{ vMode == 'NEW' &&
				<Grid item xs={12}>
					<div style={{ textAlign:'center', marginTop: "3rem"}}>
						<IconButton
							onClick={handleUploadClick}
							style={{
								width: "80px",
								height: "80px",
								backgroundColor: "rgba(19, 130, 117, 1)",
								color: "white",
								borderRadius: "50%",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
							}}
						>
							<UploadIcon style={{ fontSize: "40px" }} />
						</IconButton>
						{/* {selectedImages != 0 && 
							<IconButton
								// onClick={handleDeleteClick}
								style={{
									marginLeft: "2rem", 
									width: "80px",
									height: "80px",
									backgroundColor: "rgba(19, 130, 117, 1)",
									color: "white",
									borderRadius: "50%",
									boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
								}}
							>
								<CloseIcon style={{ fontSize: "40px" }} />
							</IconButton>} */}
					</div>
					<div style={{ display:'flex', textAlign: "center", marginTop: "1rem", justifyContent:'center' }}>
						{/* Image Preview */}
						{selectedImages.length == 0 &&
							<div>
							<Box sx={{fontWeight:'normal', textAlign:'center', marginBottom:'1rem'}}>
								<Typography sx={{fontSize:'1.3rem'}}>Upload the photo(s) of your handwritten or printed questions and answers.</Typography>
								<Typography sx={{fontSize:'1.3rem'}}>Below is the sample photo.</Typography>
							</Box>
							<img src="../sample-image.png" alt="Sample" 
								style={{
										maxWidth: "100%", 
										height: "20rem",
										objectFit: "cover",
										marginRight: "5px",
										borderRadius: "10px",
										border: "2px solid #ccc",
										borderRadius:'5%'
									}} 
									onClick={() => openImageInNewTab("../sample-image.png")}/>
							</div>
						}
						{selectedImages && selectedImages.map((img, iImg)=>(
							<div key={`boxImg${iImg}`} style={{position: 'relative', margin: '5px', width: "200px", height: "200px"}}>
								<img
									src={img.url}
									alt="Preview"
									style={{
										width: "200px",
										height: "200px",
										objectFit: "cover",
										marginRight: "5px",
										borderRadius: "10px",
										border: "2px solid #ccc",
										borderRadius:'5%'
									}}
								/>
								<IconButton
									onClick={() => handleRemoveClick(iImg)}
									style={{
										width: "25px", 
										height: "25px",
										position: 'absolute',
										top: 0, 
										right: 0, 
										color: 'rgba(255, 255, 255, 0.8)', 
										backgroundColor: 'rgba(0, 0, 0, 0.6)', 
										margin: '5px', 
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.8)',
										},
									}}>
										<CloseIcon />
									</IconButton>
							</div>
						))}

						<input
							id="file-input"
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleFileChange}
							multiple
						/>
					</div>
					{ selectedImages && selectedImages.length>0 && 
					<div style={{ display:'flex', textAlign: "center", marginTop: "5rem", justifyContent:'center' }}>
						<Button className="app-btn-bg" sx={{
							margin:'0 5px 22px 5px'
							}}
							onClick={onSubmit} color="info" variant="contained" size="large">
							Submit
						</Button>
					</div>
					}
				</Grid>
				}
				{ (vMode == 'PROCESSING' || vMode == 'DONE') &&
				<>
				<Grid item xs={12}>
          <LinearProgress color="info" sx={{ marginTop: '1rem' }} />
				</Grid>
				<Grid item xs={12} mt={5} align={'center'}>
					{ record && record.pages && record.pages.map((p, iP)=>(
						<Box display={'flex'} alignItems={'center'} justifyContent={'center'} key={'img_i_' + iP}>
                {p.status !== 1 &&
                  <CircularProgress />
                }
                {p.status === 1 &&
                  (
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularProgress variant="determinate" value={100} color="success" />
                      <Box
                        sx={{
                          top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                        }}
                      >
                        <Typography variant="caption" component="div" color="text.info">{`100%`}</Typography>
                      </Box>
                    </Box>
                  )
                }
                <Typography sx={{ marginLeft: '10px' }}>Page {iP+1}</Typography>
              </Box>
						// <div >
						// 	 {iP+1} : {p.status == 1 ? 'DONE' : 'Processing'}
						// </div>
					))}
				</Grid>
				</>
				}
				{ vMode == 'SUBMITTING' &&
				<>
				<Grid item xs={12}>
          <LinearProgress color="info" sx={{ marginTop: '1rem' }} />
				</Grid>
				<Grid item xs={12} mt={5} align={'center'}>
					<CircularProgress />
				</Grid>
				</>
				}
			</Grid>
		</Box>
		</>
	)
}