import React, { useEffect, useState, useRef } from "react";
import { AppHelper } from '../../AppHelper';
import {
  Alert, Button, Box, Card, Dialog, DialogActions, DialogTitle, Select, TextField, MenuItem
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { pink, green } from '@mui/material/colors';
import { diffWordsWithSpace } from 'diff';

export default function PopErrorAdd ({
  onSave, onClose, open, title, essay, words, popErrorAddLine, popErrorAddStartIndex, onAddDone
}){
  const [wordW, setWordW] = useState('');
  const [wordC, setWordC] = useState('');
  const [view, setView] = useState('');
  const handleClose = () =>{ onClose() };
  const [popTitle, setPopTitle] = useState('');
  const [correction, setCorrection] = useState('');
  const [explanation, setExplanation] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [type, setType] = useState('UC');
  
  const [gmType, setGmType] = useState('');
  const [gmSubtype, setGmSubtype] = useState('');

  const handleTypeChange = (e) => {
    setGmType(e.target.value);
    setGmSubtype(''); // Reset subtype when type changes
  };

  const getSubtypes = (type) => {
    const typeObj = grammarTypes.find(type => type.code === gmType);
    if (typeObj) {
      return typeObj.subtypes;
    }
    return [];
  };
  
  const grammarTypes = [
  {
    code: 'subject-verb-agreement',
    name: 'Subject Verb Agreement',
    subtypes: [
      { code: 'singular-subject-plural-verb', name: 'Singular Subject Plural Verb' },
      { code: 'plural-subject-singular-verb', name: 'Plural Subject Singular Verb' },
      {
        code: 'verb-tense',
        name: 'Verb Tense',
        subtypes: [
          { code: 'incorrect-past-tense', name: 'Incorrect Past Tense' },
          { code: 'incorrect-present-tense', name: 'Incorrect Present Tense' },
          { code: 'incorrect-future-tense', name: 'Incorrect Future Tense' },
          { code: 'inconsistent-verb-tense', name: 'Inconsistent Verb Tense' }
        ]
      },
      {
        code: 'punctuation',
        name: 'Punctuation',
        subtypes: [
          { code: 'missing-period', name: 'Missing Period' },
          { code: 'missing-comma', name: 'Missing Comma' },
          { code: 'missing-semicolon', name: 'Missing Semicolon' },
          { code: 'missing-colon', name: 'Missing Colon' },
          { code: 'missing-question-mark', name: 'Missing Question Mark' },
          { code: 'missing-double-quote', name: 'Missing Double Quote' },
          { code: 'missing-single-quote', name: 'Missing Single Quote' },
          { code: 'missing-exclamation-point', name: 'Missing Exclamation Point' },
          { code: 'incorrect-capitalization', name: 'Incorrect Capitalization' }
        ]
      },
      {
        code: 'sentence-structure',
        name: 'Sentence Structure',
        subtypes: [
          { code: 'incomplete-sentence', name: 'Incomplete Sentence' },
          { code: 'run-on-sentence', name: 'Run-on Sentence' }
        ]
      }
    ]
  },
  {
    code: 'pronoun-usage',
    name: 'Pronoun Usage',
    subtypes: [
      { code: 'incorrect-pronoun-case', name: 'Incorrect Pronoun Case' },
      { code: 'unclear-pronoun-referent', name: 'Unclear Pronoun Referent' },
      { code: 'pronoun-antecedent-agreement', name: 'Pronoun Antecedent Agreement' }
    ]
  },
  {
    code: 'modifiers',
    name: 'Modifiers',
    subtypes: [
      { code: 'misplaced-modifier', name: 'Misplaced Modifier' },
      { code: 'dangling-modifier', name: 'Dangling Modifier' }
    ]
  },
  {
    code: 'prepositions',
    name: 'Prepositions',
    subtypes: [
      { code: 'incorrect-preposition', name: 'Incorrect Preposition' },
      { code: 'unnecessary-preposition', name: 'Unnecessary Preposition' }
    ]
  },
  {
    code: 'articles',
    name: 'Articles',
    subtypes: [
      { code: 'incorrect-definite-article', name: 'Incorrect Definite Article' },
      { code: 'incorrect-indefinite-article', name: 'Incorrect Indefinite Article' },
      { code: 'missing-article', name: 'Missing Article' }
    ]
  },
  {
    code: 'spelling',
    name: 'Spelling',
    subtypes: [
      { code: 'misspelled-word', name: 'Misspelled Word' },
      { code: 'incorrect-homophone', name: 'Incorrect Homophone' }
    ]
  },
  {
    code: 'word-choice',
    name: 'Word Choice',
    subtypes: [
      { code: 'incorrect-word-usage', name: 'Incorrect Word Usage' },
      { code: 'redundant-word', name: 'Redundant Word' },
      { code: 'unnecessary-word', name: 'Unnecessary Word' }
    ]
  },
  {
    code: 'parallel-structure',
    name: 'Parallel Structure',
    subtypes: [
      { code: 'lack-of-parallel-structure', name: 'Lack of Parallel Structure' }
    ]
  },
  {
    code: 'verb-forms',
    name: 'Verb Forms',
    subtypes: [
      { code: 'incorrect-verb-form', name: 'Incorrect Verb Form' },
      { code: 'incorrect-gerund', name: 'Incorrect Gerund' },
      { code: 'incorrect-infinitive', name: 'Incorrect Infinitive' }
    ]
  },
  {
    code: 'confusing-shifts',
    name: 'Confusing Shifts',
    subtypes: [
      { code: 'shift-in-verb-tense', name: 'Shift in Verb Tense' },
      { code: 'shift-in-person', name: 'Shift in Person' },
      { code: 'shift-in-number', name: 'Shift in Number' }
    ]
  },
  {
    code: 'apostrophes',
    name: 'Apostrophes',
    subtypes: [
      { code: 'incorrect-apostrophe-possession', name: 'Incorrect Apostrophe Possession' },
      { code: 'incorrect-apostrophe-contraction', name: 'Incorrect Apostrophe Contraction' },
      { code: 'missing-apostrophe', name: 'Missing Apostrophe' }
    ]
  },
  {
    code: 'comma-usage',
    name: 'Comma Usage',
    subtypes: [
      { code: 'missing-comma-in-series', name: 'Missing Comma in Series' },
      { code: 'missing-comma-after-introductory-element', name: 'Missing Comma after Introductory Element' },
      { code: 'missing-comma-in-compound-sentence', name: 'Missing Comma in Compound Sentence' },
      { code: 'unnecessary-comma', name: 'Unnecessary Comma' }
    ]
  }
  ];

  const handleButtonClick = (argType) => {   
	  setCorrection('');
	  setDifficulty('');
	  setExplanation('');
	  setGmType('');
	  setGmSubtype(''); // Reset subtype when type changes
	  setType('UC');
    setView(argType);
    if(argType == 'GM' || argType == 'SP'){
      setCorrection(words);
    }
  };
  
  useEffect(()=>{
    if(!open) return;
    setPopTitle('ADD');
    setView('0');
  }, [open]);
  
  const onClickSave = async() => {
	  if(view == 'SP' && correction == "")
	  {
		  alert("Correct Spelling is required");
		  return;
	  }
	  if(view == 'SP' && difficulty == "")
	  {
		  alert("Difficulty is required");
		  return;
	  }
	  if(view == 'VCA' && correction == "")
	  {
		  alert("Suggested Correction is required");
		  return;
	  }
	  if(view == 'VCA' && explanation == "")
	  {
		  alert("Explanation is required");
		  return;
	  }
      console.log('onSave', correction, wordC);
	  var _user = AppHelper.userCacheGet();
      var _url = 'api/score/' + view + '/error/add'; 
	  var _payload = {token: _user.token, essayId: essay.essayId, w:words, r:correction, e:explanation, type:type, offset:popErrorAddStartIndex, d:difficulty};
	 
	  if(view == "VCX")
	  {
		  _payload = {token: _user.token, essayId: essay.essayId, w:words, type:type, offset:popErrorAddStartIndex, line:popErrorAddLine};
	  }
	  else if(view == "VCA")
	  {
		  _payload = {token: _user.token, essayId: essay.essayId, w:words, sg:correction, ex:explanation, offset:popErrorAddStartIndex, line:popErrorAddLine};
	  }
	  else if(view == "GM")
	  {
		  _payload = {token: _user.token, essayId: essay.essayId, w:words, sg:correction, ex:"-", offset:popErrorAddStartIndex, line:popErrorAddLine, type:gmType, subtype:gmSubtype, };
	  }
	  
	  var _res = await AppHelper.apiPost(_url, _payload);
	  if (!_res || !_res.status) {
		  alert('ERROR: Failed to add, ' + _res.msg);
		  return;
	  }
	  //alert("Success");
    if(onAddDone) onAddDone(view);
	  onClose();
	};
  
  return(
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{popTitle}</DialogTitle>
      <Box padding={'0.5rem'} minWidth={'25rem'}>
        <Alert variant="outlined" severity="warning">
          Selected Word(s):
          <div style={{fontWeight:'bold'}}>
          {words}
          </div>
          <div style={{fontStyle:'italic'}}>
            {popErrorAddLine}
          </div>
        </Alert>
		
			{words && true &&
			<>
			  {words.split(' ').filter(word => word.trim() !== '').length === 1 ? (
				<>
				   <Select
					value={view}
					onChange={(e) => handleButtonClick(e.target.value)}
					fullWidth
					variant="outlined"
					sx={{ marginTop: '0.5rem' }}
				  >
					<MenuItem value="GM">Grammar Mistake</MenuItem>
					<MenuItem value="SP">Spelling Mistake</MenuItem>
					<MenuItem value="VCA">Vocabulary Mistake</MenuItem>
					<MenuItem value="VCX">Vocabulary Complexity</MenuItem>
				  </Select>
				</>
			  ) : (
				 <Select
					value={view}
					onChange={(e) => handleButtonClick(e.target.value)}
					fullWidth
					variant="outlined"
					sx={{ marginTop: '0.5rem' }}
				  >
					<MenuItem value="GM">GRAMMAR MISTAKE</MenuItem>
					
				  </Select>
			  )}
			</>
			}
			
      {(view == 'SP') && (
        <>
          <TextField
            label="Correct Spelling"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '0.5rem' }}
            value={correction}
            onChange={(e) => setCorrection(e.target.value)}
          />
          <TextField
            select
            label="Difficulty"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '0.5rem' }}
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
          >
            <MenuItem value="Easy">Easy</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="Difficult">Difficult</MenuItem>
          </TextField>
         
        </>
      )}
	  
	  {view == 'VCA' && (
        <>
       
          <TextField
            label="Suggested Correction"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '0.5rem' }}
            value={correction}
            onChange={(e) => setCorrection(e.target.value)}
          />
         
		  <TextField
            label="Explanation"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '0.5rem' }}
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
          />
         
        </>
      )}
	  
	  {view == 'VCX' && (
        <>
       
          <TextField
            select
            label="Type"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '0.5rem' }}
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="UC">Uncommon</MenuItem>
            <MenuItem value="RA">Rare</MenuItem>
            
          </TextField>
         
        </>
      )}
	  
	  {view == 'GM' && (
        <>
       
          <TextField
            label="Correct Sentence"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '0.5rem' }}
            value={correction}
            onChange={(e) => setCorrection(e.target.value)}
          />
		  
		 
		  
           <TextField
        select
        label="Type"
        variant="outlined"
        fullWidth
        sx={{ marginTop: '0.5rem' }}
        value={gmType}
        onChange={handleTypeChange}
      >
        {grammarTypes.map((type) => (
          <MenuItem key={type.code} value={type.code}>
            {type.name}
          </MenuItem>
        ))}
      </TextField>
      {type && (
        <TextField
          select
          label="Subtype"
          variant="outlined"
          fullWidth
          sx={{ marginTop: '0.5rem' }}
          value={gmSubtype}
          onChange={(e) => setGmSubtype(e.target.value)}
        >
          {getSubtypes(type).map((subtype) => (
            <MenuItem key={subtype.code} value={subtype.code}>
              {subtype.name}
            </MenuItem>
          ))}
        </TextField>
		
		
      )}
	  
	  
		  
	  
	    </>
      )}

      </Box>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        { view != '0' && 
        <Button
          sx={{
            backgroundColor: 'rgb(99, 136, 137)',
            ":hover": { backgroundColor: 'rgb(157, 188, 152)' }
          }}
          color="info"
          variant="contained"
          onClick={onClickSave}
        >
          SAVE
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
