import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import '../../App.css';
import './sentence-transform-images.css';
import { AppHelper } from "../../AppHelper";

export default function SentenceTransformImages({oUser, exerciseId}) {
	const [records, setRecords] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(()=>{
		if(!oUser || !exerciseId) return;
		fetchImages();
	}, [exerciseId, oUser]);

	const fetchImages = async ()=>{
		setIsLoading(true);
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/get/images', { token: oUser.token, exerciseId: exerciseId });
		setIsLoading(false);
		if(!_res || !_res.status){
			console.log(_res);
			//alert('Failed to fetch the images!');
			return;
		}
		setRecords(_res.data);
	};
	const openImageInNewTab = (b64) => {
    const byteCharacters = atob(b64);
    const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" }); // Adjust type if needed
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank", "noopener,noreferrer");
  };
	return(
		<Box display={'flex'} justifyContent={'center'}>
			{ !isLoading && records.map((img, index) => {
        const imgSrc = img.img.startsWith("data:image/")
					? img.img
					: `data:image/png;base64,${img.img}`;

				const base64Data = img.img.startsWith("data:image/")
					? img.img.split(",")[1]
					: img.img;

        return (
			<Box key = {index} >
				<Typography marginLeft="5px" marginTop="10px">
					Page {index + 1}
				</Typography >
					<img className="exercise-view-img"
						key={index}
						onClick={() => openImageInNewTab(base64Data)}
						src={imgSrc}
						alt={`Image ${index + 1}`}
						// style={{  }}
						style={{ 
							border: `1px solid ${img.status === 1 ? 'rgba(19, 130, 117, 0.8)':'red'}`,
							borderRadius: "15px"}}

					/>
			</Box>
        );
      })
			}
			{isLoading && 
			<div>...</div>
			}
		</Box>
	);
}