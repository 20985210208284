import React, { useEffect, useState } from "react";
import { CircularProgress } from '@mui/material';
import '../../App.css';
import './sentence-transform-explainer.css';
import { AppHelper } from "../../AppHelper";

export default function SentenceTransformExplainer({oUser, question}) {
	const [record, setRecord] = useState({});
	const [originalData, setOriginalData] = useState(null);
	const [transformedData, setTransformedData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
		if(!question || !oUser) return;
		const fetchExplain = async (argQuestion)=>{
			setIsLoading(true);
			var _question = {...argQuestion};
			_question.question = _question.correctedAnswer;
			var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/explain', 
				{ 
					token: oUser.token, 
					question: _question
				});
			setIsLoading(false);
			if(!_res.status){
				alert('Failed to fetch the data Explainer!');
				return;
			}
			if(_res.data.original_and_transformed){
				var _hpOriginal = _res.data.original_and_transformed.find(x=>x.version == 'original');
				if(_hpOriginal){
					if(_hpOriginal.highlighted_parts){
						_hpOriginal.highlighted_parts.map(x=>{
							x.textView = (x.text.match(/<b>(.*?)<\/b>/) || ["", ""])[1];
						});
					}
					_hpOriginal.full_sentenceView = _hpOriginal.full_sentence;
					setOriginalData(_hpOriginal);
				}
				var _hpTransformed = _res.data.original_and_transformed.find(x=>x.version == 'transformed');
				if(_hpTransformed){
					if(_hpTransformed.highlighted_parts){
						_hpTransformed.highlighted_parts.map(x=>{
							x.textView = (x.text.match(/<b>(.*?)<\/b>/) || ["", ""])[1];
						});
					}
					_hpTransformed.full_sentenceView = _hpTransformed.full_sentence;
					setTransformedData(_hpTransformed);
				}
			}
			_res.data.steps.forEach(x=>{
				x.description2 = x.description.replace(/(["'])(.*?)\1/g, (match, quote, content) => {
					return `${quote}<b>${content}</b>${quote}`;
				});
			});
			setRecord(_res.data);
		};

		fetchExplain(question);
  }, [question, oUser]);

	const addTextHighlight = (arg)=>{
		return arg.replaceAll('<b>', '<span class="highlighted-part">')
		 					.replaceAll('</b>', '</span>');
		//console.log('addTextHighlight',arg);
		// arg.textView = arg.text2.replaceAll('<b>', '<span class="highlighted-part">')
		// 								.replaceAll('</b>', '</span>');
	};
	const stepHighlight = (argIndex)=>{
		var _hpOriginal = record.steps[argIndex].highlight_original;
		setOriginalData({...originalData, full_sentenceView: addTextHighlight(_hpOriginal)});
		var _hpTransformed = record.steps[argIndex].highlight_transformed;
		setTransformedData({...transformedData, full_sentenceView: addTextHighlight(_hpTransformed)});
	}
	const stepHighlightReset = (argIndex)=>{
		setOriginalData({...originalData, full_sentenceView: originalData.full_sentence});
		setTransformedData({...transformedData, full_sentenceView: transformedData.full_sentence});
	}

	return(
		<>
		{!isLoading && record &&
		<div id="output">
			<div className="slide">
				<div className="header-section">
					<h2>Transformation Example</h2>
					<h3>Showing Original and Transformed Sentences</h3>
				</div>
				<div className="sentence-container">
					<div className="sentence-box">
						{originalData &&
						<p dangerouslySetInnerHTML={{ __html:originalData.full_sentenceView}}></p>
						}
					</div>
					<div className="sentence-box">
						{transformedData &&
						<p dangerouslySetInnerHTML={{ __html:transformedData.full_sentenceView}}></p>
						}
					</div>
				</div>
				<table>
					<thead>
						<tr>
							<th className="explainer-th"  style={{width:'50%'}}>Original</th>
							<th className="explainer-th">Transformed</th>
						</tr>
					</thead>
					<tbody>
						{ originalData && originalData.highlighted_parts && originalData.highlighted_parts.map((hp, iHp)=>(
						<tr key={'tr01_' + iHp}>
							<td className="explainer-td"  >
								<div dangerouslySetInnerHTML={{ __html: hp.textView }}></div>
							</td>
							{ transformedData && transformedData.highlighted_parts && transformedData.highlighted_parts[iHp] &&
							<td className="explainer-td">
								<div dangerouslySetInnerHTML={{ __html: transformedData.highlighted_parts[iHp].textView }}></div>
							</td>
							}
						</tr>
						)) }
					</tbody>
				</table>
				<div className="speech">
					<h3>Steps to Understand the Transformation:</h3>
					{ record && record.steps && record.steps.map((s, iS)=>(
					<div key={'boxStep_'+iS} onMouseEnter={()=>stepHighlight(iS)} onMouseLeave={()=>stepHighlightReset(iS)} className="step">
						<a className="explain-step" dangerouslySetInnerHTML={{ __html: (iS+1) + '. ' + s.description2}}>
						</a>
					</div>
					))}
				</div>
			</div>
		</div>
		}
		{ isLoading &&
		<div style={{margin:'1rem'}}>
			<CircularProgress />
		</div>
		}
		</>
	)
}