import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
//import { makeStyles } from '@mui/styles';
import AppTopBar from './common/app-top-bar';
import AppLoading from './common/app-loading';
import SpaCheck from './common/spa-check';
import '../css/property-list.css';
import { Avatar, Container, Paper, Button, Divider, Drawer, Hidden,
  List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, 
  
  Select, Typography, MenuItem, FormControl, InputLabel, TextField, Grid, AppBar, Toolbar, IconButton 
} from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { useProperty } from '../contexts/context-property';
import { AppHelper } from '../AppHelper';
import LoadingButton from '@mui/lab/LoadingButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import SentenceTransformExplainer from './sentence-transformation/sentence-transform-explainer';
import SentenceTransformExercise from './sentence-transformation/sentence-transform-exercise';

export default function PageDev() {
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const [msgBox, setMsgBox] = React.useState({
    open: false, vertical: 'top', horizontal:'center', msg: ''
  });
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('-');
  const [feedbackTypeOK, setFeedbackTypeOK] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [question, setQuestion] = useState(null);
  var appVer = '';
  var appVerCheckId;
  //var iframeUrl = 'http://127.0.0.1:4200';
  var iframeUrl = 'https://portal-dev-2020.trakomatic.com/login?auth=token';

  const versionCheck = async ()=>{
    var _res = await AppHelper.api('api/version');
    if(_res && _res.status===1 && _res.data){
      if(appVer === '' || appVer === _res.data.v){
        appVer = _res.data.v;
        console.log('same-v:', appVer);
      }
      else{
        appVer = _res.data.v;
        console.log('v-changed:', appVer);
        if(window && 'caches' in window){
          caches.keys().then((names) => {
          names.forEach(name => {
              caches.delete(name);
          })
          })
        }
        window.location.reload(true);
      }
    }
    if(appVerCheckId) clearTimeout(appVerCheckId);
    appVerCheckId = setTimeout(() => {
      versionCheck();
    }, 1000*10);
  };
  //versionCheck();

  useEffect(()=>{
    setQuestion({
      "question_number": 68,
      "original_sentence": "\"Where was the laptop placed yesterday?\" Raj asked Nelson.",
      "question": "Raj asked Nelson <u>where the laptop was placed yesterday</u>",
    });
  }, []);

  const onClick1 = ()=>{
    var _iframe1 = document.getElementById('iframe1');
    console.log('iframe1', _iframe1);
    var _msg = {code:'AUTH', token:'YFG1966r3EgragB3sSpVRoNCFp4LWmDn1721804714828'};
    var _res = _iframe1.contentWindow.postMessage(_msg, iframeUrl);
  }


  return (
    <>
      {/* <SentenceTransformExplainer question={question} oUser={oUser}></SentenceTransformExplainer> */}
      <SentenceTransformExercise question={question} oUser={oUser}></SentenceTransformExercise>
    </>
  );
}
