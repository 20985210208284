import { createContext, useContext, useEffect, useState } from 'react';
import { AppHelper } from '../AppHelper';
import { EssayHelper } from '../helpers/essay.helper';
import { UserContext } from './context-user';

const dayjs = require('dayjs');
const ExerciseContext = createContext();

export const useExerciseContext = () =>{
  return useContext(ExerciseContext);
};

export const ExerciseProvider = ({ children })=>{
  const { oUser } = useContext(UserContext);
  const [recList, setRecList] = useState([]);
  const [essayIdSelected, setEssayIdSelected] = useState('');
  const [isListLoaded, setIsListLoaded] = useState(false);
  const [isListLoading, setIsListLoading] = useState(true);
  useEffect(() => {
    if(!oUser) return;
    loadList(oUser);
  }, [oUser]);

  const loadList = async (argUser, argExerciseId=null) => {
    if(!argUser) return;
    setIsListLoading(true);
    var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/list', {token: argUser.token, profileId: argUser.profileId});
    // setIsEssayListLoaded(true);
    // setIsEssayListLoading(false);
    if(!_res || !_res.status){
      setIsListLoaded(true);
      setIsListLoading(false);
      setRecList([]);
      console.log('set empty []', _res);
      return;
    }
    var _list = _res.data;
    _list.forEach((r, iR)=>{
      r.viewTitle = `Exercise ${iR+1}`;
      r.qCount = 0;
      r.qCountY = 0;
      r.pages.forEach(recP=>{
        recP.questions.forEach(recQ=>{
          r.qCount++;
          if(recQ.status == 1) r.qCountY++;
        });
      });
      //r.pages.map(p=> r.qCount += p.questions.length);
      r.scoreTitle = `${r.qCountY}/${r.qCount}`;// + 
      var _ts = dayjs(r.timestampUtc);
      r.tCode = _ts.format('YYYYMMDDHHmmss');
      r.tTitle = _ts.format(`DD MMM YY' hh:mm A`);
    });
    var _list = [...AppHelper.sortList(_res.data, 'tCode', 2)];
    setRecList(_list);
    //console.log('setRecList', _list);
    setIsListLoaded(true);
    setIsListLoading(false);
    //if(1==1) return;
    if(argExerciseId){
      recSelectByList(argExerciseId, _list);
    }
  };
  const recSelect = (argExerciseId)=>{
	
    var _list = [...recList];
    _list.forEach(x=>{
      x.selected = x.exerciseId == argExerciseId ? '1' : '0';
    });
    setRecList(_list);
  }
  const recSelectByList = (argExerciseId, _list)=>{
    _list.forEach(x=>{
      x.selected = x.exerciseId == argExerciseId ? '1' : '0';
    });
    setRecList(_list);
  }
  const recRemove = (argExerciseId)=>{
    var _list = [...recList];
    _list = _list.filter(x=>x.exerciseId != argExerciseId);
    _list.map(x=>x.selected = 0);
    setRecList(_list);
  }

  return (
    <ExerciseContext.Provider value={{recList, setRecList, loadList, isListLoaded, setIsListLoaded, recSelect, isListLoading, recRemove}}>
      { children }
    </ExerciseContext.Provider>
  )
}
