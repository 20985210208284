import React, { useState, useEffect, useRef } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from "@mui/material/CssBaseline";
import PopUsageList from './pop-usage-list';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { alpha } from "@mui/material/styles";
import { CheckCircle, Delete, Block } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import AppTopBar2 from "./common/app-top-bar2";
import AppLoading from "./common/app-loading";
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PopInviteEmail from './pop-invite-email';
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import {
  Avatar,
  AppBar,
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  ToggleButton,
  Switch,
  Fab,
  Container,
  Divider,
  Drawer,
  TablePagination,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  Toolbar,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  Grid,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import LinearProgress from "@mui/material/LinearProgress";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PhoneNumber from "libphonenumber-js";
import { AppHelper } from "../AppHelper";
import { EssayHelper } from "../helpers/essay.helper";
import EssayForm from "./essay/essay-form";
import EssayView from "./essay/essay-view";
import SpaCheck from "./common/spa-check";
const dayjs = require("dayjs");

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          fontSize={"0.7rem"}
          sx={{
            color: props.recSelected == 1 ? "#fff" : "text.secondary",
          }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function stableSortProfile(array, comparator) {
  const stabilizedThis = array.filter((x)=>x.type=="Account").map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCellsEssay = [
  { id: "rowIndex", numeric: true, disablePadding: false, label: "#" },
  { id: "grade", numeric: false, disablePadding: false, label: "GRADE" },
  { id: "topic", numeric: false, disablePadding: false, label: "TITLE" },
  { id: "avgScore", numeric: true, disablePadding: false, label: "SCORE" },
  { id: "version", numeric: false, disablePadding: false, label: "VERSION " },
  { id: "vCorr", numeric: false, disablePadding: false, label: "CORRECTION" },
  { id: "tCode", numeric: false, disablePadding: false, label: "SUBMITTED ON" },
  { id: "email", numeric: false, disablePadding: false, label: "SUBMITTED BY" },
  { id: "name", numeric: false, disablePadding: false, label: "PROFILE" },
  { id: "essayId", numeric: false, disablePadding: false, label: "..." },
];
const headCellsProfile = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "essayCount", numeric: true, disablePadding: false, label: "ESSAYS" },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "lastLogin",
    numeric: false,
    disablePadding: false,
    label: "LAST LOGIN",
  },
  {
    id: "accountType",
    numeric: false,
    disablePadding: false,
    label: "ACCOUNT TYPE",
  },
  { id: "status", numeric: false, disablePadding: false, label: "STATUS" },
];

const headCellsAcc = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "accountType", numeric: false, disablePadding: false, label: "ACCOUNT TYPE" },
  {
    id: "totalProfiles",
    numeric: true,
    disablePadding: false,
    label: "PROFILES",
  },
  { id: "totalEssays", numeric: true, disablePadding: false, label: "ESSAYS" },
  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  {
    id: "tCodeCreatedOn",
    numeric: true,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "tCodeLastLogin",
    numeric: true,
    disablePadding: false,
    label: "LAST LOGIN",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "ACTION",
  },
];


const headCellsTui = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },

  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  {
    id: "tCodeCreatedOn",
    numeric: true,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "tCodeLastLogin",
    numeric: true,
    disablePadding: false,
    label: "LAST LOGIN",
  },
];

const headCellsTut = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },

  { id: "status", numeric: true, disablePadding: false, label: "STATUS" },
  {
    id: "tCodeCreatedOn",
    numeric: true,
    disablePadding: false,
    label: "CREATED ON",
  },
  {
    id: "tCodeLastLogin",
    numeric: true,
    disablePadding: false,
    label: "LAST LOGIN",
  },
];

const headCellsFeedback = [
  { id: "i", numeric: false, disablePadding: false, label: "" },
  { id: "profileId", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "essayId", numeric: false, disablePadding: false, label: "ESSAY" },
  { id: "feedback", numeric: false, disablePadding: false, label: "FEEDBACK" },
  {
    id: "feedbackType",
    numeric: false,
    disablePadding: false,
    label: "FEEDBACK TYPE",
  },
  {
    id: "createdOn",
    numeric: false,
    disablePadding: false,
    label: "TIMESTAMP",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  console.log("props.gid", props.gid);
  const headCells =
    props.gid == "A1"
      ? headCellsAcc
      : props.gid == "G1"
        ? headCellsEssay
        : props.gid == "T1"
          ? headCellsTui
          : props.gid == "F1"
            ? headCellsFeedback
            : props.gid == "R1"
              ? headCellsTut
              : headCellsProfile;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PageSaEssayListV2() {
  // const theme = createTheme({
  //   typography: {
  //     fontFamily: 'Noto Sans',
  //   }
  // });
  const navigate = useNavigate();
  const oUser = AppHelper.userCacheGet();
  const { pageEssayId } = useParams();
  const isPhoneVerifed =
    oUser && oUser.phoneNumber && oUser.phoneNumber.length > 0;
  const today = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [essayList, setEssayList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [originalProfileList, setOriginalProfileList] = useState([]);

  const [tutorList, setTutorList] = useState([]);
  const [originalTutorList, setOriginalTutorList] = useState([]);

  const [accList, setAccList] = useState([]);
  const [originalAccList, setOriginalAccList] = useState([]);
  const [tuiList, setTuiList] = useState([]);
  const [originalTuiList, setOriginalTuiList] = useState([]);

  const [tutList, setTutList] = useState([]);
  const [originalTutList, setOriginalTutList] = useState([]);

  const [feedbackList, setFeedbackList] = useState([]);

  const otpRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [viewMode, setViewMode] = React.useState("CARDS");
  const [refreshList, setRefreshList] = React.useState(false);
  const [recEssay, setRecEssay] = React.useState({});
  const [autoSelectId, setAutoSelectId] = React.useState("");
  const [countEssay, setCountEssay] = React.useState(0);
  const [countExercise, setCountExercise] = React.useState(0);
  const [countUser, setCountUser] = React.useState(0);
  const [countAcc, setCountAcc] = React.useState(0);
  const [countTui, setCountTui] = React.useState(0);
  const [countTut, setCountTut] = React.useState(0);
  const [countFeedback, setCountFeedback] = React.useState(0);

  const [tuiton, setTuiton] = React.useState("");

  const [tblOrderEssay, setTblOrderEssay] = React.useState("desc");
  const [tblOrderByEssay, setTblOrderByEssay] = React.useState("tCode");
  const [filterEssay, setFilterEssay] = React.useState("");

  const [tblOrderProfile, setTblOrderProfile] = React.useState("desc");
  const [tblOrderByProfile, setTblOrderByProfile] =
    React.useState("essayCount");
  const [filterProfile, setFilterProfile] = React.useState("");

  const [tblOrderAcc, setTblOrderAcc] = React.useState("desc");
  const [tblOrderByAcc, setTblOrderByAcc] = React.useState("totalProfiles");
  const [filterAcc, setFilterAcc] = React.useState("");

  const [tblOrderTui, setTblOrderTui] = React.useState("desc");
  const [tblOrderByTui, setTblOrderByTui] = React.useState("totalProfiles");
  const [filterTui, setFilterTui] = React.useState("");

  const [tblOrderTut, setTblOrderTut] = React.useState("desc");
  const [tblOrderByTut, setTblOrderByTut] = React.useState("totalProfiles");
  const [filterTut, setFilterTut] = React.useState("");

  const [tblOrderFeedback, setTblOrderFeedback] = React.useState("desc");
  const [tblOrderByFeedback, setTblOrderByFeedback] =
    React.useState("createdOn");
  const [filterFeedback, setFilterFeedback] = React.useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [enhancedTransription, setEnhancedTransription] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewUsageClick = (row) => {
    setSelectedAccount(row.accountId);
    setIsPopUsageOpen(!isPopUsageOpen);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const popUsageListClose = (questionId) => {
    setIsPopUsageOpen(false);
  }

  useEffect(() => {
    AppHelper.apiAuditPost("P-SA", "");
  }, []);

  useEffect(() => {


    fetchConfig();

  }, []);

  const toggleCollapse = (row) => {
    setProfileList((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile.profileId === row.profileId || row.accountId == profile.accountId
          ? { ...profile, isExpanded: !profile.isExpanded }
          : profile
      )
    );
  };

  const fetchConfig = async () => {
    setIsLoading(true);
    var _res = await AppHelper.apiPost('api/setting/list', { token: oUser.token });
    console.log('_res', _res);
    if (!_res || !_res.status) {
      setIsLoading(false);
      return;
    }
    var enhancedTransription = _res.data.filter((k) => k.code == 'enhanced.transcription')[0];

    if (enhancedTransription) {
      setEnhancedTransription(parseInt(enhancedTransription.value));
    }
    else {
      setEnhancedTransription(1);
    }
    //setIsLoading(false);
    setRefreshList(false);
  };

  const handleEnhancedTransriptionChange = async () => {
    const newState = enhancedTransription == 0 ? 1 : 0;
    setEnhancedTransription(newState);

    var _res = await AppHelper.apiPost("api/setting/update", {
      token: oUser.token,
      code: 'enhanced.transcription',
      value: newState,
    });

  };


  const scoreCodesV2_sa = [
    {
      c: "GM",
      c2: "grammar",
      n: "Grammar",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "SP",
      c2: "spelling",
      n: "Spelling",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "VC",
      c2: "vocabulary",
      n: "Vocabulary",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    {
      c: "ST",
      c2: "structure",
      n: "Structure",
      v: 0,
      p: 0,
      eg: [],
      sub: [],
      expand: false,
    },
    //{ c:'FL', c2: 'flow', n:'Flow', v:0, p:0 },
  ];

  useEffect(() => {
    if (!oUser) {
      navigate("/login");
      return;
    }
  });

  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day'));
  const [endDate, setEndDate] = useState(dayjs());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    //console.log(date, endDate);
    if (endDate && date && endDate < date) {
      setEndDate(date);
    } else if (!endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    //console.log(date, startDate);
    if (startDate && date && date < startDate) {
      setStartDate(date);
    } else if (!startDate) {
      setStartDate(date);
    }
  };

  const [isIncludeDeletedUser, setIsIncludeDeletedUser] = useState(false);
  const [isIncludeDeletedAcc, setIsIncludeDeletedAcc] = useState(false);
  const [isIncludeDeletedTui, setIsIncludeDeletedTui] = useState(false);
  const [isIncludeDeletedTut, setIsIncludeDeletedTut] = useState(false);
  const [popInviteEmailOpen, setPopInviteEmailOpen] = useState(false);
  const [isPopUsageOpen, setIsPopUsageOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    var _list = originalProfileList.filter(
      (x) => (x.status == 1 || isIncludeDeletedUser)
    );
    setProfileList(_list);
    setCountUser(originalProfileList.filter(
      (x) => x.type == "Profile" && (x.status == 1 || isIncludeDeletedUser)
    ).length);
  }, [isIncludeDeletedUser]); // Run this effect whenever isIncludeDeletedUser changes

  useEffect(() => {
    var _list = originalAccList.filter(
      (x) => x.status == 1 || isIncludeDeletedAcc
    );
    setAccList(_list);
    setCountAcc(_list.length);
  }, [isIncludeDeletedAcc]); // Run this effect whenever isIncludeDeletedUser changes

  useEffect(() => {
    var _list = originalTuiList.filter(
      (x) => x.status == 1 || isIncludeDeletedTui
    );
    setTuiList(_list);
    setCountTui(_list.length);
  }, [isIncludeDeletedTui]);

  useEffect(() => {
    var _list = originalTutList.filter(
      (x) => x.status == 1 || isIncludeDeletedTui
    );
    setTutList(_list);
    setCountTut(_list.length);
  }, [isIncludeDeletedTut]);

  const handleIncludeDeletedUser = (event) => {
    setIsIncludeDeletedUser(!isIncludeDeletedUser);
  };

  const handleIncludeDeletedAcc = (event) => {
    setIsIncludeDeletedAcc(!isIncludeDeletedAcc);
  };
  const handleIncludeDeletedTui = (event) => {
    setIsIncludeDeletedTui(!isIncludeDeletedTui);
  };

  const handleIncludeDeletedTut = (event) => {
    setIsIncludeDeletedTut(!isIncludeDeletedTut);
  };

  const handleAccountTypeChange = async (event, profileId) => {
    const newAccountType = event.target.value;
    if (
      window.confirm(
        "Are you sure you want to update the profile as " +
        (newAccountType == "PAR"
          ? "Parent"
          : newAccountType == "STU"
            ? "Student"
            : newAccountType == "TUI"
              ? "Tuition Center"
              : "Parent")
      )
    ) {
      var _res = await AppHelper.apiPost("api/account/updateAccountType", {
        isSA: 1,
        token: oUser.token,
        accountType: newAccountType,
        profileId: profileId,
      });
      console.log("_res", _res);
      if (!_res || !_res.status) {
        setIsLoading(false);
        return;
      } else {
        if (_res && _res.status) {
          const updatedProfiles = profileList.map((profile) => {
            if (profile.profileId === profileId) {
              return { ...profile, accountType: newAccountType };
            }
            return profile;
          });

          // Update state or props with the updatedProfiles
          setProfileList(updatedProfiles);
          alert("Account type updated successfully");
        }
      }
    }
  };

  var _rulesByProfile = {};
  useEffect(() => {
    _rulesByProfile = {};
    const fetchEssayList = async () => {
      essayList.splice(0);
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/essay/list/sa", {
        token: oUser.token,
      });
      console.log("_res", _res);
      if (!_res || !_res.status) {
        setIsLoading(false);
        return;
      }
      //_res.data = _res.data.filter(x=>x.essayId=='da6b655a-c74a-45e8-b80f-d132e645f089');
      console.log("sa", _res.data);
      _res.data.forEach((r, iRec) => {
        var _ts = dayjs(r.timestampUtc);
        r.rowIndex = 0;
        r.tCode = _ts.format("YYYYMMDDHHmmss");
        r.tTitle = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');
        r.viewTitle = `${r.topic}`;
        r.avgScore = parseFloat(r.avgScore);
        r.avgScoreP = (r.avgScore / 5) * 100;
        r.email = r.ete_profile ? r.ete_profile.email : "-";
        r.name = r.ete_profile ? r.ete_profile.name : "-";
        r.version = "1.0";
        r.vCorr = r.vCorr ? r.vCorr : "-";
        if (r.scores && r.scores.length > 0) {
          r.version =
            r.scores[0].version == "3.10"
              ? "3.1"
              : "3.00"
                ? "3.0"
                : "2.20"
                  ? "2.2"
                  : r.scores[0].version == "2.10"
                    ? "2.1"
                    : r.scores[0].version == "2.00"
                      ? "2.0"
                      : "1.0";
          if (r.version == "2.0" || r.version == "2.1" || r.version == "2.2") {
            var _scores = [...scoreCodesV2_sa];
            var _v2TotalScore = 0;
            _scores.forEach((_rScore) => {
              var _arrScRec = r.scores.filter(
                (_x) => _x.scoreCode == _rScore.c
              );
              const _scSum = _arrScRec.reduce(
                (total, current) => total + current.score,
                0
              );
              _v2TotalScore +=
                _arrScRec.length > 0 ? _scSum / _arrScRec.length : 0;
            });
            r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
            r.avgScoreP = parseFloat(((r.avgScore / 16) * 100).toFixed(2));
          } else if (r.version == "3.0" || r.version == "3.1") {
            var _scores = [...scoreCodesV2_sa];
            var _v2TotalScore = 0;
            _scores.forEach((_rScore) => {
              var _arrScRec = r.scores.filter(
                (_x) => _x.scoreCode == _rScore.c
              );
              var _scSum = _arrScRec.reduce(
                (total, current) => total + current.score,
                0
              );
              if (_rScore.c == "SP" || _rScore.c == "GM" || _rScore.c == "VC") {
                _scSum = parseFloat((_scSum / 2.5).toFixed(2));
              }
              if (_rScore.c == "VC" && _arrScRec.length < 2) {
                _scSum = parseFloat((_scSum / 2).toFixed(2));
              }
              _v2TotalScore +=
                _arrScRec.length > 0 ? _scSum / _arrScRec.length : 0;
            });
            r.avgScore = parseFloat(_v2TotalScore.toFixed(2));
            r.avgScoreP = parseFloat(((r.avgScore / 16) * 100).toFixed(2));
          }
        }
      });
      var _list = [...AppHelper.sortList(_res.data, "tCode", 2)];
      //setEssayList(_list);
      updateWithScoreRules(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // fetchProfileList(_list);
      // fetchAccList();
      // fetchFeedbackList();
      // setCountEssay(_list.length);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }

      // if(pageEssayId){
      //   var _pageEssay = _list.find(r=>r.essayId == pageEssayId);
      //   if(_pageEssay){
      //     essayViewClick(_pageEssay);
      //   }
      // }
    };
    const updateWithScoreRules = async (_list) => {
      if (!_list || _list.length < 1) return;
      setIsLoading(true);

      setEssayList(_list);
      setIsLoading(false);

      setTimeout(() => {
        console.log("essayList", _list);
      }, 1000);
      fetchProfileList(_list);
      fetchAccList();
      fetchTuiList();
      fetchTutList();
      fetchFeedbackList();
	  fetchExerciseList();
      setIsLoading(false);
      setCountEssay(_list.length);
      if (autoSelectId && autoSelectId != "") {
        var _recEssay = _list.find((r) => r.essayId == autoSelectId);
        if (_recEssay) onEssaySelect(_recEssay);
        setAutoSelectId("");
      }

      if (pageEssayId) {
        var _pageEssay = _list.find((r) => r.essayId == pageEssayId);
        if (_pageEssay) {
          essayViewClick(_pageEssay);
        }
      }
    };
    const fetchRules = async (argProfileId) => {
      const [
        _rulesJsonP1,
        _rulesJsonP2,
        _rulesJsonP3,
        _rulesJsonP4,
        _rulesJsonP5,
        _rulesJsonP6,
      ] = await Promise.all([
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P1"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P2"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P3"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P4"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P5"),
        EssayHelper.rulesJsonByProfileId(oUser.token, argProfileId, "P6"),
      ]);
      var _rulesJsonList = [
        { code: "P1", data: _rulesJsonP1 },
        { code: "P2", data: _rulesJsonP2 },
        { code: "P3", data: _rulesJsonP3 },
        { code: "P4", data: _rulesJsonP4 },
        { code: "P5", data: _rulesJsonP5 },
        { code: "P6", data: _rulesJsonP6 },
      ];
      console.log(_rulesJsonList);
      return _rulesJsonList;
    };
    const fetchProfileList = async (argEssays) => {
      essayList.splice(0);
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/account/list/sa", {
        token: oUser.token,
      });
      //setIsLoading(false);
      //console.log('_resAcc', _res);
      if (!_res || !_res.status) {
        return;
      }
      var idx = 1;
      _res.data.forEach((r, i) => {
        var _ts = dayjs(r.createdOn);
        if (r.type == "Account") {
          r.i = idx++;
        }

        r.tCode = _ts.format("YYYYMMDDHHmmss");
        r.tTitle = `` + _ts.format(`DD MMM 'YY HH:mm`);


        if (r.type == "Profile") {
          r.essays = argEssays.filter((e) => e.profileId == r.profileId);
          r.essayCount = r.essays.length;
        }


        r.createdDate = _ts.toDate();
        r.tTitle = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');

        _ts = dayjs(r.lastLogin);
        r.lTitle = _ts.format(`DD MMM 'YY HH:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` + _ts.format('DD MMM YYYY');

        r.isExpanded = false;
      });

      var _list = _res.data;// [...AppHelper.sortList(_res.data, "", 2)];
      setOriginalProfileList(_list);

      if (!isIncludeDeletedUser) {
        _res.data = _res.data.filter((x) => x.status == 1);
      }
      _list = _list.filter((x) => x.accountType != 'TUI');
      setCountUser(_list.filter((x) => x.accountType != 'TUI').length);

      _list = _res.data; // [...AppHelper.sortList(_res.data, "", 2)];
      setProfileList(_list);

      console.log(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }

    };

    const fetchAccList = async () => {
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/account/list/sa/v1", {
        token: oUser.token,
      });
      //setIsLoading(false);
      //console.log('_resAcc', _res);
      if (!_res || !_res.status) {
        return;
      }	  
      _res.data.forEach((r, i) => {
        r.i = i;
        if (r.lastLogin) {
          var _ts = dayjs(r.lastLogin);
          r.lastLogin = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeLastLogin = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.lastLogin = "";
          r.tCodeLastLogin = "";
        }

        if (r.createdOn) {
          var _ts = dayjs(r.createdOn);
          r.createdOn = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeCreatedOn = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.createdOn = "";
          r.tCodeCreatedOn = "";
        }
      });

      var _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];
      setOriginalAccList(_list);

      if (!isIncludeDeletedAcc) {
        _res.data = _res.data.filter((x) => x.status == 1);
      }
      //_res.data = _res.data.filter((x) => x.accountType != 'TUI');
      _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];
      setAccList(_list);

      console.log(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }
      setCountAcc(_list.length);
    };


    const fetchTuiList = async () => {
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/account/list/sa", {
        token: oUser.token,
      });
      //setIsLoading(false);
      //console.log('_resTui', _res);
      if (!_res || !_res.status) {
        return;
      }
      _res.data.forEach((r, i) => {
        r.i = i;
        if (r.lastLogin) {
          var _ts = dayjs(r.lastLogin);
          r.lastLogin = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeLastLogin = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.lastLogin = "";
          r.tCodeLastLogin = "";
        }

        if (r.createdOn) {
          var _ts = dayjs(r.createdOn);
          r.createdOn = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeCreatedOn = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.createdOn = "";
          r.tCodeCreatedOn = "";
        }
      });

      var _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];
      setOriginalTuiList(_list);

      if (!isIncludeDeletedTui) {
        _res.data = _res.data.filter((x) => x.status == 1);
      }
      _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];
      _list = _list.filter((x) => x.accountType == 'TUI');
      setTuiList(_list);

      console.log(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }
      setCountTui(_list.length);
    };

    const fetchTutList = async () => {
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/account/list/sa/", {
        token: oUser.token,
      });
      //setIsLoading(false);
      //console.log('_resTut', _res);
      if (!_res || !_res.status) {
        return;
      }
      _res.data.forEach((r, i) => {
        r.i = i;
        if (r.lastLogin) {
          var _ts = dayjs(r.lastLogin);
          r.lastLogin = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeLastLogin = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.lastLogin = "";
          r.tCodeLastLogin = "";
        }

        if (r.createdOn) {
          var _ts = dayjs(r.createdOn);
          r.createdOn = _ts.format(`DD MMM 'YY hh:mm a`).toUpperCase(); //`Week ${_ts.week()} - ` +
          r.tCodeCreatedOn = _ts.format("YYYYMMDDHHmmss");
        } else {
          r.createdOn = "";
          r.tCodeCreatedOn = "";
        }
      });

      var _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];
      setOriginalTutList(_list);

      if (!isIncludeDeletedTut) {
        _res.data = _res.data.filter((x) => x.status == 1);
      }
      _list = [...AppHelper.sortList(_res.data, "totalProfiles", 2)];

      _list = _list.filter((x) => x.accountType == 'TEA');
      console.log("TEA", _list);
      setTutList(_list);

      console.log(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }
      setCountTut(_list.length);
    };

    const fetchFeedbackList = async () => {
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/feedback/list", {
        token: oUser.token,
        startDate: startDate,
        endDate: endDate,
      });
      //setIsLoading(false);
      if (!_res || !_res.status) {
        return;
      }
      _res.feedbackList.forEach((r, i) => {
        var _ts = dayjs(r.createdOn);
        r.i = i;
        r.createdOn = _ts.format(`DD MMM 'YY HH:mm`);
      });

      var _list = [...AppHelper.sortList(_res.feedbackList, "feedback", 1)];

      setFeedbackList(_list);

      console.log(_list);
      // setTimeout(() => { console.log('essayList', _list); }, 1000);
      // if(autoSelectId && autoSelectId != ''){
      //   var _recEssay = _list.find(r=>r.essayId == autoSelectId);
      //   if(_recEssay) onEssaySelect(_recEssay);
      //   setAutoSelectId('');
      // }
      setCountFeedback(_list.length);
    };
	
	const fetchExerciseList = async () => {
      setIsLoading(true);
      var _res = await AppHelper.apiPost("api/exercise/sentenceTransform/list/all", {
        token: oUser.token,       
      });      
      if (!_res || !_res.status) {
        return;
      }
           
      setCountExercise(_res.data.length);
    };

    const fetchIAM = async () => {
      if (!oUser) {
        navigate("/login");
        return;
      }
      var _res = await AppHelper.apiPost("api/account/iam", {
        token: oUser.token,
      });
      if (!_res || !_res.status || !_res.data || !_res.data.isSa) {
        setIsLoading(false);
        nonSaView();
        return;
      }
      fetchEssayList();
    };
    fetchIAM();
    //fetchEssayList();
  }, [refreshList]);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  const onEssaySelect = (arg) => {
    setViewMode("VIEW");
    setRecEssay(arg);
    var _list = essayList;
    _list.forEach((s) => {
      s.selected = s.essayId == arg.essayId ? "1" : "0";
    });
  };
  const essayDelete = async (arg) => {
    if (!window.confirm("Are you sure you want to delete the Essay?")) return;
    setIsLoading(true);
    var _res = await AppHelper.apiPost("api/essay/remove", {
      token: oUser.token,
      essayId: arg.essayId,
    });
    if (!_res || !_res.status) {
      alert("ERROR: Failed to delete!");
      setIsLoading(false);
      return;
    }
    setRefreshList(!refreshList);
  };
  const essayFormDone = (arg) => {
    if (arg.code && arg.code == "NEW") {
      setAutoSelectId(arg.essayId);
    }
    setRefreshList(!refreshList);
  };
  const essayViewClick = (arg) => {
    setRecEssay(arg);
    setViewMode("VIEW");
  };
  const essayViewDone = (arg) => {
    setRefreshList(!refreshList);
    setViewMode("NEW");
  };
  const essayListView = () => {
    //setViewMode("LIST");
    navigate("/sa/essay");
  };
  const exerciseListView = () => {
    //setViewMode("LIST");
    navigate("/sa/exercise");
  };
  const profileListView = () => {
    setViewMode("LIST-PROFILE");
  };
  const accListView = () => {
    setViewMode("LIST-ACC");
  };
  const tuiListView = () => {
    setViewMode("LIST-TUI");
  };
  const tutListView = () => {
    setViewMode("LIST-TUT");
  };
  const feedbackListView = () => {
    //setViewMode('LIST-FEEDBACK');
    navigate("/sa/feedbacks");
  };

  const onInviteEmail = (arg) => {
    setTuiton(arg.email);
    setPopInviteEmailOpen(true);
  }

  const popInviteEmailClose = () => {
    setTuiton("");
    setPopInviteEmailOpen(false);
  }

  const cardView = () => {
    setIsIncludeDeletedUser(false);
    setViewMode("CARDS");
  };
  const nonSaView = () => {
    setViewMode("NON-SA");
  };
  const drawerItemColrs = {
    "1x": "rgba(0,0,0,0.25)",
    "1xx": "rgb(157, 188, 152)",
    1: "rgb(99, 136, 137)",
    "0x": "white",
    0: "rgb(235, 217, 180)",
  };
  const drawerItemColorsText = {
    1: "rgba(255,255,255,1)",
    0: "rgba(0,0,0,0.6)",
  };
  const drawer = (
    <>
      <div style={{ overflowY: "auto", marginBottom: "110px" }}>
        <Box width={"100%"}>
          <List sx={{ fontFamily: "Noto Sans" }}>
            {/* {['Week 12 - Essay B', 'Week 12 - Essay A', 'Week 11 - Essay B', 'Week 11 - Essay A'].map((text, index) => ( */}
            {essayList.map((rec, index) => (
              <React.Fragment key={rec.essayId}>
                <ListItem
                  sx={{
                    backgroundColor: rec.selected
                      ? drawerItemColrs[rec.selected]
                      : drawerItemColrs["0"],
                    color: rec.selected
                      ? drawerItemColorsText[rec.selected]
                      : drawerItemColorsText["0"],
                  }}
                  disablePadding
                >
                  <ListItemButton onClick={() => onEssaySelect(rec)}>
                    <div style={{ widht: "100%", flex: 1 }}>
                      <Box>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {rec.viewTitle}
                        </Typography>
                      </Box>
                      <div>
                        <LinearProgressWithLabel
                          value={rec.avgScoreP}
                          recSelected={rec.selected}
                        />
                      </div>
                      <Typography
                        sx={{
                          display: "inline",
                          color: rec.selected
                            ? drawerItemColorsText[rec.selected]
                            : drawerItemColorsText["0"],
                          opacity: "0.8",
                        }}
                        component="span"
                        variant="body2"
                      >
                        {rec.tTitle}
                      </Typography>
                    </div>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Box>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 50,
          right: 0,
          left: 0,
          height: "60px",
          borderTop: "0 solid rgba(0,0,0,0.12)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Button sx={{
        backgroundColor:'rgb(99, 136, 137)', 
        ":hover":{backgroundColor:'rgb(157, 188, 152)'},
        width:'100%',
        margin:'0 5px 22px 5px'
        }}
        onClick={essayAddClick} color="info" variant="contained" size="large">
        + ADD
      </Button> */}
      </div>
    </>
  );
  const mobileOpenClick = (arg) => {
    setMobileOpen(!mobileOpen);
  };
  const back2Home = () => {
    navigate("/");
  };
  const listSortAndFilterEssay = () => {
    var _list = [...essayList];
    if (filterEssay && filterEssay.length > 0) {
      var _filterText = filterEssay.toLowerCase();
      _list = _list.filter(
        (r) =>
          r.grade.toLowerCase().includes(_filterText) ||
          r.topic.toLowerCase().includes(_filterText) ||
          r.email.toLowerCase().includes(_filterText) ||
          r.version.toLowerCase().includes(_filterText) ||
          r.tTitle.toLowerCase().includes(_filterText) ||
          r.avgScoreP.toString().toLowerCase().includes(_filterText)
      );
    }
    return stableSort(_list, getComparator(tblOrderEssay, tblOrderByEssay));
  };
  const filterEssayChange = (event) => {
    setFilterEssay(event.target.value);
  };

  const visibleEssays = React.useMemo(
    () => listSortAndFilterEssay(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],

    [essayList, tblOrderEssay, tblOrderByEssay, filterEssay]
  );
  const tblSortEssay = (event, property) => {
    const isAsc = tblOrderByEssay === property && tblOrderEssay === "asc";
    setTblOrderEssay(isAsc ? "desc" : "asc");
    setTblOrderByEssay(property);
  };
  const listSortAndFilterProfile = () => {
    var _list = [...profileList];
    if (filterProfile && filterProfile.length > 0) {
      var _filterText = filterProfile.toLowerCase();
      _list = _list.filter(
        (r) =>
          r.name.toLowerCase().includes(_filterText) ||
          r.email.toLowerCase().includes(_filterText) ||
          r.tTitle.toLowerCase().includes(_filterText) ||
          r.essayCount.toString().toLowerCase().includes(_filterText)
      );
    }
	
	const finalList = [];
	const profileRows = _list.filter((item) => item.type === 'Profile');
	const _sortedList = stableSortProfile(
		_list,
		getComparator(tblOrderProfile, tblOrderByProfile)
	  );
	
	_sortedList.forEach((row) => {
		finalList.push(row);
		
		profileRows.forEach((profileRow) => {
			if (profileRow.accountId === row.accountId) {
				finalList.push(profileRow);
				}
			});
	});

	return finalList;
  };

  const listSortAndFilterAcc = () => {
    var _list = [...accList];
    if (filterAcc && filterAcc.length > 0) {
      var _filterText = filterAcc.toLowerCase();
      _list = _list.filter((r) => r.email.toLowerCase().includes(_filterText));
    }
    return stableSort(_list, getComparator(tblOrderAcc, tblOrderByAcc));
  };

  const listSortAndFilterTui = () => {
    var _list = [...tuiList];
    if (filterTui && filterTui.length > 0) {
      var _filterText = filterTui.toLowerCase();
      _list = _list.filter((r) => r.email.toLowerCase().includes(_filterText));
    }
    return stableSort(_list, getComparator(tblOrderTui, tblOrderByTui));
  };

  const listSortAndFilterTut = () => {
    var _list = [...tutList];
    if (filterTut && filterTut.length > 0) {
      var _filterText = filterTut.toLowerCase();
      _list = _list.filter((r) => r.email.toLowerCase().includes(_filterText));
    }
    return stableSort(_list, getComparator(tblOrderTut, tblOrderByTut));
  };

  const listSortAndFilterFeedback = () => {
    var _list = [...feedbackList];
    if (filterFeedback && filterFeedback.length > 0) {
      var _filterText = filterFeedback.toLowerCase();
      _list = _list.filter(
        (r) =>
          r.profileId.toLowerCase().includes(_filterText) ||
          r.feedback.toLowerCase().includes(_filterText) ||
          r.feedbackType.toLowerCase().includes(_filterText) ||
          r.essayId.toLowerCase().includes(_filterText)
      );
    }
    return stableSort(
      _list,
      getComparator(tblOrderFeedback, tblOrderByFeedback)
    );
  };

  const filterProfileChange = (event) => {
    setFilterProfile(event.target.value);
  };
  const filterAccChange = (event) => {
    setFilterAcc(event.target.value);
  };
  const filterTuiChange = (event) => {
    setFilterTui(event.target.value);
  };
  const filterTutChange = (event) => {
    setFilterTut(event.target.value);
  };
  const filterFeedbackChange = (event) => {
    setFilterFeedback(event.target.value);
  };
  const visibleProfiles = React.useMemo(
    () => listSortAndFilterProfile(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],
    [profileList, tblOrderProfile, tblOrderByProfile, filterProfile]
  );
  const visibleAccs = React.useMemo(
    () => listSortAndFilterAcc(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],
    [accList, tblOrderAcc, tblOrderByAcc, filterAcc]
  );

  const visibleTuis = React.useMemo(
    () => listSortAndFilterTui(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],
    [tuiList, tblOrderTui, tblOrderByTui, filterTui]
  );

  const visibleTuts = React.useMemo(
    () => listSortAndFilterTut(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],
    [tutList, tblOrderTut, tblOrderByTut, filterTut]
  );

  const visibleFeedbacks = React.useMemo(
    () => listSortAndFilterFeedback(),
    //stableSort(essayList, getComparator(tblOrderEssay, tblOrderByEssay))
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // )
    //[order, orderBy, page, rowsPerPage],
    [accList, tblOrderFeedback, tblOrderByFeedback, filterFeedback]
  );

  const tblSortProfile = (event, property) => {
    const isAsc = tblOrderByProfile === property && tblOrderProfile === "asc";
    setTblOrderProfile(isAsc ? "desc" : "asc");
    setTblOrderByProfile(property);
  };

  const tblSortAcc = (event, property) => {
    const isAsc = tblOrderByAcc === property && tblOrderAcc === "asc";
    setTblOrderAcc(isAsc ? "desc" : "asc");
    setTblOrderByAcc(property);
  };

  const tblSortTui = (event, property) => {
    const isAsc = tblOrderByTui === property && tblOrderTui === "asc";
    setTblOrderTui(isAsc ? "desc" : "asc");
    setTblOrderByTui(property);
  };

  const tblSortTut = (event, property) => {
    const isAsc = tblOrderByTut === property && tblOrderTut === "asc";
    setTblOrderTut(isAsc ? "desc" : "asc");
    setTblOrderByTut(property);
  };

  const tblSortFeedback = (event, property) => {
    const isAsc = tblOrderByFeedback === property && tblOrderFeedback === "asc";
    setTblOrderFeedback(isAsc ? "desc" : "asc");
    setTblOrderByFeedback(property);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppTopBar2 mobileOpen={mobileOpen} mobileOpenClick={mobileOpenClick} />
        {isLoading ? (
          <AppLoading />
        ) : (
          <>
            {/* <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0, md:0 }, border:'0 solid red' }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'none' },
            minWidth: '300px'
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            backgroundColor:'red',
            minWidth:'300px'
          }}
          PaperProps={{
            sx: {
              backgroundColor: "#f9efdb", //color: "red",
              marginTop:'65px',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box> */}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                //width: { md: `calc(100% - ${drawerWidth}px)` },
                width: "100%",
                marginTop: "50px",
              }}
            >
              {viewMode === "CARDS" && (
                <>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <React.Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h3" component="div">
                                {countEssay}
                              </Typography>
                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                ESSAYS
                              </Typography>
                              <Typography variant="body2">
                                Total number of submitted essays.
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button onClick={essayListView} size="small">
                                More
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
						
						
						
						
						<Grid item xs={12} sm={4}>
						  <Card variant="outlined">
							<CardContent>
							  <Typography variant="h3" component="div">
								{countExercise}
							  </Typography>
							  <Typography sx={{ mb: 1.5 }} color="text.secondary">
								EXERCISES
							  </Typography>
							  <Typography variant="body2">
								Total number of submitted exercises.
							  </Typography>
							</CardContent>
							<CardActions>
							  <Button onClick={exerciseListView} size="small">
								More
							  </Button>
							</CardActions>
						  </Card>
						</Grid>
					

                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h3" component="div">
                                {countUser}
                              </Typography>
                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                PROFILES
                              </Typography>
                              <Typography variant="body2">
                                Total number of active profiles.
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button onClick={profileListView} size="small">
                                More
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h3" component="div">
                                {countAcc}
                              </Typography>
                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                ACCOUNTS
                              </Typography>
                              <Typography variant="body2">
                                Total number of active accounts.
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button onClick={accListView} size="small">
                                More
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h3" component="div">
                                {countTui}
                              </Typography>
                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                TUITION CENTERS
                              </Typography>
                              <Typography variant="body2">
                                Total number of active tuition center.
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button onClick={tuiListView} size="small">
                                More
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h3" component="div">
                                {countTut}
                              </Typography>
                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                TUTORS
                              </Typography>
                              <Typography variant="body2">
                                Total number of active tutors.
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button onClick={tutListView} size="small">
                                More
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Typography variant="h3" component="div">
                                {countFeedback}
                              </Typography>
                              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                FEEDBACKS
                              </Typography>
                              <Typography variant="body2">
                                Total number of feedbacks. (past 7 days)
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button onClick={feedbackListView} size="small">
                                More
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Card variant="outlined">
                            <CardContent>
                              <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6" component="div">
                                  Enhanced Transcription
                                </Typography>
                                <Switch
                                  checked={enhancedTransription === 1}
                                  onChange={handleEnhancedTransriptionChange}
                                  inputProps={{ 'aria-label': 'enhanced transcription switch' }}
                                />
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </>
              )}

              {viewMode == "LIST" && (
                <>
                  <Grid container>
                    <Grid item xs={6} sm={6}>
                      <Button onClick={cardView}>&laquo; Back to Overview</Button>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        value={filterEssay}
                        onChange={filterEssayChange}
                        id="txtFilterEssay"
                        label=""
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      {/* <TableHead>
                  <TableRow>
                    <TableCell align="center">GRADE</TableCell>
                    <TableCell align="left">TITLE</TableCell>
                    <TableCell align="center">SCORE</TableCell>
                    <TableCell align="center">SUBMITTED ON</TableCell>
                    <TableCell align="left">SUBMITTED BY</TableCell>
                  </TableRow>
                </TableHead> */}

                      <EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderEssay}
                        orderBy={tblOrderByEssay}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortEssay}
                        rowCount={essayList.length}
                        gid="G1"
                      />
                      <TableBody>
                        {visibleEssays
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, iRec) => (
                            <TableRow
                              key={row.essayId}
                              sx={{
                                
                              }}
                            >
                              <TableCell align="center">{iRec + 1}.</TableCell>
                              <TableCell align="left">{row.grade}</TableCell>
                              <TableCell component="th" scope="row">
                                {/* <Button href={'sa/' + row.essayId} onClick={()=> essayViewClick(row)}>{row.topic}</Button> */}
                                <Button
                                  href={"/essay/detail/" + row.essayId}
                                  target="_blank"
                                >
                                  {row.topic}
                                </Button>
                              </TableCell>
                              <TableCell align="right">
                                {row.avgScoreP}%
                              </TableCell>
                              <TableCell align="left">{row.version}</TableCell>
                              <TableCell align="left">{row.vCorr}</TableCell>
                              <TableCell align="left">{row.tTitle}</TableCell>
                              <TableCell align="left">
                                {row.ete_profile ? row.ete_profile.email : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {row.ete_profile ? row.ete_profile.name : "-"}
                              </TableCell>
                              <TableCell align="left">
                                <IconButton
                                  onClick={() => essayDelete(row)}
                                  aria-label="delete"
                                >
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={visibleEssays.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
              {viewMode == "LIST-PROFILE" && (
                <>
                  <Grid container>
                    <Grid item xs={3} sm={3}>
                      <Button onClick={cardView}>&laquo; Back to Overview</Button>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {1 == 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isIncludeDeletedUser}
                              onChange={handleIncludeDeletedUser}
                            />
                          }
                          label="Include Deleted User"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        value={filterProfile}
                        onChange={filterProfileChange}
                        id="txtFilterEssay"
                        label=""
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                 
				
				<EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderProfile}
                        orderBy={tblOrderByProfile}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortProfile}
                        gid="P1"
                        rowCount={profileList.length}
                      />
                      
                      <TableBody>
                        {(() => {
                          let accountCounter = 0; // Initialize the counter for Account rows

                          return visibleProfiles.map((row) => {
                            if (row.type === "Account") {
                              accountCounter += 1; // Increment the counter only for rows with type 'Account'
                            }

                            return (row.type === "Account" || row.isExpanded) && (
                              <React.Fragment key={row.profileId}>
                                <TableRow
                                  sx={{
                                    
                                  }}
                                >
                                  <TableCell align="center">
                                    {row.type === "Account" ? accountCounter : ""}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.type === "Profile" && (
                                      <CircleIcon
                                        style={{
                                          fontSize: 8,
                                          marginLeft: 15,
                                          marginRight: 8,
                                          color: "black",
                                        }}
                                      />
                                    )}
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="left">{row.email}</TableCell>
                                  <TableCell align="right">{row.essayCount}</TableCell>
                                  <TableCell align="left">{row.tTitle}</TableCell>
                                  <TableCell align="left">{row.lTitle}</TableCell>
                                  <TableCell align="left">
                                    {row.type === "Account" && (
                                      <Select
                                        value={row.accountType}
										fullWidth 
                                        onChange={(event) =>
                                          handleAccountTypeChange(event, row.profileId)
                                        }
                                        displayEmpty
                                        inputProps={{ "aria-label": "Account Type" }}
                                      >
                                        <MenuItem value="STU">Student</MenuItem>
                                        <MenuItem value="TEA">Tutor</MenuItem>
                                        <MenuItem value="PAR">Parent</MenuItem>
                                        <MenuItem value="TUI">Tuition Center</MenuItem>
                                      </Select>
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.status === 1 && <CheckCircle />}
                                    {row.status === 2 && <Delete />}
                                    {row.status === 0 && <Block />}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.type === "Account" && (
                                      <IconButton
                                        onClick={() => toggleCollapse(row)}
                                        aria-label="Expand row"
                                      >
                                        {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>

                              </React.Fragment>
                            );
                          });
                        })()}
                      </TableBody>

                    </Table>
                  </TableContainer>
                </>
              )}

              {viewMode == "LIST-ACC" && (
                <>
                  <Grid container>
                    <Grid item xs={3} sm={3}>
                      <Button onClick={cardView}>&laquo; Back to Overview</Button>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {1 == 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isIncludeDeletedAcc}
                              onChange={handleIncludeDeletedAcc}
                            />
                          }
                          label="Include Deleted Account"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        value={filterAcc}
                        onChange={filterAccChange}
                        id="txtFilterAccount"
                        label=""
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderAcc}
                        orderBy={tblOrderByAcc}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortAcc}
                        gid="A1"
                        rowCount={accList.length}
                      />
                      <TableBody>
                        {visibleAccs.map((row, iAcc) => (
                          <TableRow
                            key={row.accountId}
                            sx={{
                              
                            }}
                          >
                            <TableCell align="center">{iAcc + 1}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.accountType == 'STU' ? 'Student'
                              : row.accountType == 'PAR' ? 'Parent'
                                : row.accountType == 'TEA' ? 'Tutor'
                                  : row.accountType == 'TUI' ? 'Tuition Center'
                                    : ''
                            }</TableCell>

                            <TableCell align="right">
                              {row.totalProfiles}
                            </TableCell>
                            <TableCell align="right">{row.totalEssays}</TableCell>
                            <TableCell align="right">
                              {row.status === 1 && <CheckCircle />}
                              {row.status === 2 && <Delete />}
                              {row.status === 0 && <Block />}
                            </TableCell>
                            <TableCell align="right">{row.createdOn}</TableCell>
                            <TableCell align="right">{row.lastLogin}</TableCell>
                            <TableCell align="right">

                              <IconButton
                                onClick={() => handleViewUsageClick(row)}
                                aria-label="View usage"
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}


              {viewMode == "LIST-TUI" && (
                <>
                  <Grid container>
                    <Grid item xs={3} sm={3}>
                      <Button onClick={cardView}>&laquo; Back to Overview</Button>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {1 == 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isIncludeDeletedTui}
                              onChange={handleIncludeDeletedTui}
                            />
                          }
                          label="Include Deleted Tuition Center"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        value={filterTui}
                        onChange={filterTuiChange}
                        id="txtFilterTuiount"
                        label=""
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderTui}
                        orderBy={tblOrderByTui}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortTui}
                        gid="T1"
                        rowCount={tuiList.length}
                      />
                      <TableBody>
                        {visibleTuis.map((row, iTui) => (
                          <TableRow
                            key={row.accountId}
                            sx={{
                              
                            }}
                          >
                            <TableCell align="center">{iTui + 1}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.email}
                              <Tooltip title="share via Email">
                                <IconButton onClick={() => onInviteEmail(row)} area-label="invite via Email">
                                  <EmailIcon style={{ color: '#00695c' }} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>


                            <TableCell align="right">
                              {row.status === 1 && <CheckCircle />}
                              {row.status === 2 && <Delete />}
                              {row.status === 0 && <Block />}
                            </TableCell>
                            <TableCell align="right">{row.createdOn}</TableCell>
                            <TableCell align="right">{row.lastLogin}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}


              {viewMode == "LIST-TUT" && (
                <>
                  <Grid container>
                    <Grid item xs={3} sm={3}>
                      <Button onClick={cardView}>&laquo; Back to Overview</Button>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {1 == 1 && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isIncludeDeletedTut}
                              onChange={handleIncludeDeletedTut}
                            />
                          }
                          label="Include Deleted Tutor"
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        value={filterTut}
                        onChange={filterTutChange}
                        id="txtFilterTutount"
                        label=""
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderTut}
                        orderBy={tblOrderByTut}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortTut}
                        gid="T1"
                        rowCount={tutList.length}
                      />
                      <TableBody>
                        {visibleTuts.map((row, iTut) => (
                          <TableRow
                            key={row.accountId}
                            sx={{
                              
                            }}
                          >
                            <TableCell align="center">{iTut + 1}</TableCell>
                            <TableCell align="left">{row.name}

                            </TableCell>
                            <TableCell align="left">{row.email}

                            </TableCell>

                            <TableCell align="right">
                              {row.status === 1 && <CheckCircle />}
                              {row.status === 2 && <Delete />}
                              {row.status === 0 && <Block />}
                            </TableCell>
                            <TableCell align="right">{row.createdOn}</TableCell>
                            <TableCell align="right">{row.lastLogin}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {viewMode == "LIST-FEEDBACK" && (
                <>
                  <Grid container>
                    <Grid item xs={3} sm={3}>
                      <Button onClick={cardView}>&laquo; Back to Overview</Button>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <Box m={0.5} pt={1}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          label="Start Date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Box m={0.5} pt={1}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          label="End Date"
                          value={endDate}
                          defaultValue={today}
                          onChange={handleEndDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={3} sm={3}>
                      <Box m={0.5} pt={1}>
                        <TextField
                          value={filterFeedback}
                          onChange={filterFeedbackChange}
                          id="txtFilterFeedbackount"
                          label=""
                          variant="standard"
                          placeholder="Search"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <EnhancedTableHead
                        //numSelected={selected.length}
                        order={tblOrderFeedback}
                        orderBy={tblOrderByFeedback}
                        //onSelectAllClick={handleSelectAllClick}
                        onRequestSort={tblSortFeedback}
                        gid="F1"
                        rowCount={feedbackList.length}
                      />
                      <TableBody>
                        {visibleFeedbacks.map((row, iFeedback) => (
                          <TableRow
                            key={row.accountId}
                            sx={{
                              
                            }}
                          >
                            <TableCell align="center">{iFeedback + 1}</TableCell>
                            <TableCell align="left">{row.profileId}</TableCell>
                            <TableCell align="left">{row.essayId}</TableCell>
                            <TableCell align="left">{row.feedback}</TableCell>
                            <TableCell align="left">{row.feedbackType}</TableCell>
                            <TableCell align="left">{row.createdOn}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {viewMode == "VIEW" && (
                <>
                  <Box>
                    <Button onClick={essayListView}>&laquo; Back to List</Button>
                  </Box>
                  <EssayView
                    essayFormDone={essayViewDone}
                    essayId={recEssay.essayId}
                  ></EssayView>
                </>
              )}
              {viewMode == "NON-SA" && (
                <React.Fragment>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h5" component="div">
                            Access denied
                          </Typography>
                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            &nbsp;
                          </Typography>
                          <Typography variant="body2">
                            This page is only allowed for System Administrators.
                            {/* <br />
                    {'"a benevolent smile"'} */}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button onClick={back2Home} size="small">
                            Back to Home
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Box>
          </>
        )}
        <SpaCheck></SpaCheck>
      </Box>
      <PopInviteEmail
        open={popInviteEmailOpen} tuiton={tuiton} onClose={popInviteEmailClose}
      />
      <PopUsageList
        open={isPopUsageOpen}
        accountId={selectedAccount}
        onClose={popUsageListClose}
      ></PopUsageList>
    </>
  );
}
