import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from '@mui/material';
import '../../App.css';
import './sentence-transform-exercise.css';
import { AppHelper } from "../../AppHelper";
import SentenceTransformExerciseView from "./sentence-transform-exercise-view";

export default function SentenceTransformExercise({oUser, question, submitDone}) {
	const [records, setRecords] = useState([]);
	const [viewMode, setViewMode] = useState('FORM');
	const [inputValues, setInputValues] = useState([]);
	const [questionSubmitted, setQuestionSubmitted] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
		if(!question || !oUser) return;
		const fetchExplain = async (argQuestion)=>{
			setIsLoading(true);
			argQuestion.original_sentence = argQuestion.originalSentence;
			var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/exerciseGenerate', { token: oUser.token, questions: [argQuestion] });
			setIsLoading(false);
			if(!_res.status){
				alert('Failed to fetch the exercise data!');
				return;
			}
			setRecords(_res.data.questions);
			var _inputs = [];
			_res.data.questions.map(q=>{
				const parser = new DOMParser();
				const doc = parser.parseFromString(q.question, 'text/html');
				const uElements = Array.from(doc.querySelectorAll('u'));
				_inputs.push(uElements.map((node) => node.textContent));
				// uElements.forEach(u=>{
				// 	_inputTextList.push(u.textContent);
				// });
			});
			setInputValues(_inputs);
		};

		fetchExplain(question);
  }, [question, oUser]);

	useEffect(()=>{
		if(viewMode == 'SUCCESS'){
			// setTimeout(() => {
			// 	setViewMode('FINISH');
			// }, 1000*2);
		}
	}, [viewMode]);

	const renderEditableText = (str, listIndex) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const elements = Array.from(doc.body.childNodes);

    return elements.map((node, nodeIndex) => {
      if (node.nodeName === 'U') {
        return (
          <input
            key={`${listIndex}-${nodeIndex}`}
            type="text"
						className="txt-exercise"
            //value={inputValues[listIndex][nodeIndex]}
            onChange={(e) => handleInputChange(listIndex, nodeIndex, e.target.value)}
            style={{ margin: '0 5px' }}
          />
        );
      } else {
        return node.textContent;
      }
    });
  };

  const handleInputChange = (listIndex, inputIndex, value) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[listIndex][inputIndex] = value;
    setInputValues(updatedInputValues);
  };

  const handleSubmit = async() => {
		var _isAnyEmpy = false;
		var _records = records;
    _records.map((rec, listIndex) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(rec.question, 'text/html');
      const elements = Array.from(doc.body.childNodes);

      const updatedStr = elements
        .map((node, nodeIndex) => {
          if (node.nodeName === 'U') {
						if(!inputValues[listIndex][nodeIndex]) _isAnyEmpy = true;
            return `<u>${inputValues[listIndex][nodeIndex]}</u>`;
          } else {
            return node.textContent;
          }
        })
        .join('');
			rec.answer = updatedStr;
			rec.userInput = inputValues[listIndex];
    });
		
		if(_isAnyEmpy){
			alert('Please fill all the blanks!');
			return;
		}
		var _exercises = [];
		_records.forEach(r=>{
			_exercises.push({
				originalSentence: r.original_sentence,
				question: r.answer,
				status: r.question == r.answer ? 1 : 3,
				correctedAnswer: r.question, 
				userInput: r.userInput
			})
		});
		setViewMode('IS-LOADING');
		var _res = await AppHelper.apiPost('api/exercise/sentenceTransform/exerciseSubmit', { 
			token: oUser.token, 
			questionId: question.questionId, 
			exercises: _exercises
		});
		if(!_res.status){
			alert('Submission failed!');
			return;
		}
		_exercises.forEach((x, iX)=>{
			if(_res.data && _res.data.length>iX){
				x.status = _res.data[iX].status;
				x.correctedAnswer = _res.data[iX].correctedAnswer;
			}
		});
		if(submitDone){
			setQuestionSubmitted({generatedExercises: _exercises, exerciseY: _exercises.filter(x=>x.status==1).length});
			submitDone(_exercises, question.questionId);
			setViewMode('SUCCESS');
		}
  };

	return(
		<>
			{viewMode == 'FORM' && 
			<div style={{marginLeft:'.5rem', borderTop:'1px solid rgba(0,0,0,.12)', paddingTop:'.3rem'}}>
				{!isLoading && inputValues &&
				<div id="boxExercise">
					<div style={{fontSize:'1.3rem', fontWeight:'bold', marginBottom:'.7rem', color:'rgba(19,130,117,1)'}}>EXERCISE</div>
					{records.map((rec,iRec)=>(
						<div key={'exercise_new_' + iRec} style={{fontWeight:'normal', marginBottom:'.5rem'}}>
							<div>Q: {rec.original_sentence}</div>
							<div>
								A: {renderEditableText(rec.question, iRec)}
							</div>
						</div>
					))}
					<div>
						<Button className="app-btn-bg" sx={{
							margin:'1rem 0 0 0'
							}}
							onClick={handleSubmit} color="info" variant="contained" size="large">
							Submit
						</Button>
					</div>
				</div>
				}
				{ isLoading &&
				<div style={{margin:'1rem'}}>
					<CircularProgress />
				</div>
				}
			</div>
			}
			{viewMode == 'IS-LOADING' &&
			<div style={{margin:'1rem'}}>
				<CircularProgress />
			</div>
			}
			{viewMode == 'SUCCESS' &&
			<div>
				<SentenceTransformExerciseView question={questionSubmitted} />
			</div>
			// <div style={{display:'flex', alignItems:'center'}}>
			// 	<svg class="checkmark" viewBox="0 0 100 100">
			// 		<circle class="checkmark-circle" cx="50" cy="50" r="47" />
			// 		<path class="checkmark-tick" d="M30 50 L45 65 L70 35" />
			// 	</svg>
			// 	<div style={{marginLeft:'.3rem'}}>Submitted</div>
			// </div>	
			}
		</>
	)
}